
import { defineComponent, PropType, ref, Ref, onMounted } from 'vue'
import Accordion from 'primevue/accordion'
import AccordionTab from 'primevue/accordiontab'

type FaqsType = {
  _id: number;
  question: string;
  answer: string;
};
export default defineComponent({
  name: 'AccordionFAQS',
  components: {
    Accordion,
    AccordionTab
  },
  props: {
    items: {
      type: Array as PropType<FaqsType[]>,
      default: () => [],
      required: true
    }
  },
  setup (props) {
    const faqs: Ref<FaqsType[]> = ref([])

    const translateFAQs = () => {
      props.items.forEach((faq: any) => {
        const { _id, question, answer } = faq
        faqs.value.push({ _id, question, answer })
      })
    }

    onMounted(translateFAQs)

    return { faqs }
  }
})
