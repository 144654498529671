import { defineComponent, ref, onMounted, defineExpose, watch } from 'vue'
import Listbox from 'primevue/listbox'
import { ToastLife, ToastSeverities, ToastSummaries } from '@/models/primevue/toast'
import { useToast } from 'primevue/usetoast'
import { findLanguagesTalents } from '@/api/talents.api'
import { useFiltersTalentStore } from '@/store/modules/commons/filters-talent-store'
import { useI18n } from 'vue-i18n'
import MultiSelectCheckbox from '@/components/commons/MultiSelectCheckbox/MultiSelectCheckbox.vue'

const levels = [
  'languages.level.beginner',
  'languages.level.intermediate',
  'languages.level.advanced',
  'languages.level.bilingual'
]

export default defineComponent({
  name: 'FilterLanguage',
  components: {
    Listbox,
    MultiSelectCheckbox
  },
  setup () {
    const assignedFiltersTalentStore = useFiltersTalentStore()
    const selectedLanguages = ref<string[]>([])
    const selectedLevels = ref<string[]>([])
    const languages = ref()
    const toast = useToast()
    const { t } = useI18n({ useScope: 'global' })
    const opLanguageSelector = ref()

    const getLanguages = async () => {
      try {
        languages.value = await findLanguagesTalents()
        languages.value = languages.value[0].languages.map((language: any) => {
          return { name: language, value: language }
        })
      } catch (error) {
        toast.add({
          summary: ToastSummaries.Error,
          detail: t('talents.alerts.loadError'),
          severity: ToastSeverities.Error,
          life: ToastLife.Default
        })
        console.error(error)
      }
    }

    onMounted(() => {
      getLanguages()

      selectedLanguages.value = assignedFiltersTalentStore.languages.language.slice()
      selectedLevels.value = assignedFiltersTalentStore.languages.level.slice()
    })

    const updateLanguages = (event: any) => {
      selectedLanguages.value = event
      if (selectedLanguages.value.length === 0) {
        selectedLevels.value = []
      }
      applyFilter()
    }
    const applyFilter = () => {
      assignedFiltersTalentStore.updateLanguages({
        language: selectedLanguages.value,
        level: selectedLevels.value
      })
    }
    function clearFilter () {
      selectedLanguages.value = []
      selectedLevels.value = []
      opLanguageSelector.value.removeItemsSelected()
    }
    watch(selectedLevels, applyFilter)

    defineExpose({
      clearFilter
    })

    return {
      levels,
      selectedLanguages,
      selectedLevels,
      updateLanguages,
      languages,
      applyFilter,
      clearFilter,
      opLanguageSelector
    }
  }
})
