import { defineComponent, onMounted, Ref, ref, defineExpose } from 'vue'
import RadioButton from 'primevue/radiobutton'
import { useFiltersTalentStore } from '@/store/modules/commons/filters-talent-store'
import { getCategories } from '@/api/talents.api'
import { CategoriesTalent } from '@/models/talents'
import MultiSelectCheckbox from '@/components/commons/MultiSelectCheckbox/MultiSelectCheckbox.vue'

export default defineComponent({
  name: 'FilterCourse',
  components: {
    RadioButton,
    MultiSelectCheckbox
  },
  props: {
  },
  setup () {
    const assignedFiltersTalentStore = useFiltersTalentStore()
    const selectedCategories = ref<string[]>([])
    const categories = ref<CategoriesTalent[]>([])
    const opCoursesSelector = ref()

    const getCategoriesList = async () => {
      try {
        categories.value = (await getCategories()).map((category: any) => {
          return { name: category.name, value: category.name, key: category.key }
        })
      } catch (error) {
        console.error(error)
      }
    }

    onMounted(() => {
      getCategoriesList()
    })

    selectedCategories.value = assignedFiltersTalentStore.coursesCategories.slice()
    const filteredCategories: Ref<CategoriesTalent[]> = ref([])
    const autocomplete = ref<string>('')
    const searchCourse = () => {
      filteredCategories.value = []
      filteredCategories.value = categories.value.filter((category) => category.name.toLowerCase().startsWith(autocomplete.value.toLowerCase()))
    }
    const updateCategories = (event: any) => {
      console.log(event)
      const auxCategories = categories.value.filter(category => event.includes(category.name)).map(category => category.key)
      selectedCategories.value = auxCategories
      if (selectedCategories.value.length !== assignedFiltersTalentStore.getTotalCourseCategories) {
        applyFilter()
      }
      autocomplete.value = ''
    }

    const applyFilter = () => {
      assignedFiltersTalentStore.updateCourseCategories(selectedCategories.value)
    }

    function clearFilter () {
      opCoursesSelector.value.removeItemsSelected()
    }

    defineExpose({
      clearFilter
    })

    return {
      categories,
      assignedFiltersTalentStore,
      searchCourse,
      autocomplete,
      updateCategories,
      applyFilter,
      clearFilter,
      opCoursesSelector
    }
  }
})
