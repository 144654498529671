
import { defineComponent, ref, onMounted, PropType } from 'vue'
import { CoderCourse } from '@/models/talents'

export default defineComponent({
  name: 'EducationTable',
  props: {
    courses: {
      required: true,
      type: Object as PropType<Array<CoderCourse>>
    }
  },

  setup (props) {
    const gotTop10 = ref(false)
    const showFirstOnly = ref(true)

    const searchTop10 = () => {
      gotTop10.value = props.courses.some((course: CoderCourse) => course.topTen ? course.topTen > 0 : false)
    }

    const toggleTableView = () => {
      showFirstOnly.value = !showFirstOnly.value
    }

    onMounted(searchTop10)

    return { gotTop10, showFirstOnly, toggleTableView }
  }
})

