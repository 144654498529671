export class CoderCourse {
  category: string
  name: string
  startDate: Date
  endDate: Date | null
  status: string
  attendance: number
  score: number
  pendingClasses: number
  attachedDocuments: string
  topTen: number

  constructor (
    category: string,
    name: string,
    startDate: Date,
    endDate: Date | null,
    status: string,
    attendance: number,
    score: number,
    pendingClasses: number,
    attachedDocuments: string,
    topTen: number
  ) {
    this.category = category
    this.name = name
    this.startDate = startDate
    this.endDate = endDate
    this.status = status
    this.attendance = attendance
    this.score = score
    this.pendingClasses = pendingClasses
    this.attachedDocuments = attachedDocuments
    this.topTen = topTen
  }
}

export interface Education {
  institution: string
  startDate: Date
  endDate: Date | null
  degree: string
  fieldOfStudy: string
  description: string
  attachedDocuments: string
}

export interface LanguageLevel {
  code: string
  name: string
  level: string
}

export interface Country {
  name: string
  code: string
}
export interface Location {
  country: Country
  city: string
}

export interface SocialNetworks {
  linkedin: string | null
  behance: string | null
  github: string | null
  youtube: string | null
  dribbble: string | null
  vimeo: string | null
  instagram: string | null
  facebook: string | null
  twitter: string | null
  website: string | null
}

export interface WorkExperience {
   position: string
   company: string
   startDate: Date
   endDate: Date | null
   description: string
}

export interface Skill {
    type: string
    name: string
}

export interface LocationsTalentDto {
  name: string
  cities: string[]
}

export interface FinalAssignment {
  name: string,
  link: string
}
export interface CoursesTalentDto {
  category: string
  courses: string[]
}
export interface CategoriesTalent {
  name: string
  value: string
  key: string
}
export interface ITalent {
  name: string
  email: string,
  presentation: string
  coderCourses: CoderCourse[]
  location: Location
  avatar: string
  background: string,
  education: Education[]
  languages: LanguageLevel[]
  skills: Skill[]
  socialNetworks: SocialNetworks
  workExperience: WorkExperience[],
  curriculum: string,
  readonly _id: string
}

export class Talent {
  name: string
  email: string
  presentation: string
  coderCourses: CoderCourse[]
  location: Location
  avatar: string
  background: string
  education: Education[]
  languages: LanguageLevel[]
  skills: Skill[]
  socialNetworks: SocialNetworks
  workExperience: WorkExperience[]
  curriculum: string
  _id!: string
  readonly id: string

  constructor (
    name: string,
  	email: string,
  	presentation: string,
  	coderCourses: CoderCourse[],
  	location: Location,
  	avatar: string,
  	background: string,
  	education: Education[],
  	languages: LanguageLevel[],
  	skills: Skill[],
  	socialNetworks: SocialNetworks,
  	workExperience: WorkExperience[],
  	curriculum: string,
  	_id: string
  ) {
    this.id = _id
  	this.name = name
  	this.avatar = avatar
  	this.presentation = presentation
  	this.coderCourses = coderCourses
  	this.education = education
  	this.languages = languages
  	this.location = location
  	this.skills = skills
  	this.socialNetworks = socialNetworks
  	this.workExperience = workExperience
  	this.curriculum = curriculum
  	this.background = background
  	this.email = email
  }
}
export interface TalentDto {
  count: number
  totalPages: number
  skip: number
  limit: number
  data: Talent[]
}
