import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-b9a4055a"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "p-d-flex p-flex-column p-jc-start p-ai-center p-mb-6 p-mb-md-0" }
const _hoisted_2 = { class: "p-col-12 profile-details-container" }
const _hoisted_3 = { class: "header-name-content" }
const _hoisted_4 = { class: "p-col-6 p-lg-8 p-md-6 p-sm-6" }
const _hoisted_5 = { class: "avatar__name" }
const _hoisted_6 = { class: "p-col-6 p-lg-4 p-md-6 p-sm-6 contact-btn-container" }
const _hoisted_7 = { class: "info-container p-d-flex" }
const _hoisted_8 = { class: "avatar" }
const _hoisted_9 = ["src"]
const _hoisted_10 = { class: "avatar__title" }
const _hoisted_11 = { class: "location__field location__field--location" }
const _hoisted_12 = ["href"]
const _hoisted_13 = { class: "social-networks__iconDiv p-d-flex" }
const _hoisted_14 = ["src", "alt"]
const _hoisted_15 = {
  key: 0,
  class: "attached-documents"
}
const _hoisted_16 = ["src"]
const _hoisted_17 = ["href"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ButtonContactar = _resolveComponent("ButtonContactar")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("figcaption", _hoisted_5, _toDisplayString(_ctx.talent?.name), 1)
        ]),
        _createElementVNode("div", _hoisted_6, [
          _createVNode(_component_ButtonContactar, {
            email: _ctx.talent?.email,
            talentName: _ctx.talent?.name,
            talentId: _ctx.talent?.id
          }, null, 8, ["email", "talentName", "talentId"]),
          _createElementVNode("button", {
            class: "ch-button-grey ch-buttom-custom back-container",
            onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('goBack')))
          }, _toDisplayString(_ctx.$t('talents.profile.button.back')), 1)
        ])
      ]),
      _createElementVNode("div", _hoisted_7, [
        _createElementVNode("figure", _hoisted_8, [
          _createElementVNode("img", {
            src: _ctx.talent?.avatar || require('@/assets/icons/avatar.png'),
            alt: "profile-picture",
            class: "avatar__img"
          }, null, 8, _hoisted_9)
        ]),
        _createElementVNode("div", null, [
          _createElementVNode("figcaption", _hoisted_10, _toDisplayString(_ctx.$t(_ctx.talent?.background)), 1),
          _createElementVNode("span", _hoisted_11, _toDisplayString(_ctx.$t(_ctx.talent.location.country.name)) + " - " + _toDisplayString(_ctx.talent.location.city), 1),
          _createElementVNode("div", {
            class: _normalizeClass(["social-networks p-d-flex p-flex-wrap p-jc-start p-col-12", {'p-jc-center': Object.keys(_ctx.socialNetworks).length == 2}])
          }, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.socialNetworks, (link, socialNetwork) => {
              return (_openBlock(), _createElementBlock("a", {
                target: "_blank",
                key: socialNetwork,
                href: link
              }, [
                _createElementVNode("div", _hoisted_13, [
                  _createElementVNode("img", {
                    class: "p-d-block",
                    src: require(`@/assets/icons/${socialNetwork}.svg`),
                    shape: "circle",
                    alt: socialNetwork
                  }, null, 8, _hoisted_14)
                ])
              ], 8, _hoisted_12))
            }), 128)),
            (_ctx.talent?.curriculum)
              ? (_openBlock(), _createElementBlock("span", _hoisted_15, [
                  _createElementVNode("img", {
                    class: "p-mx-1 p-d-block",
                    src: require('@/assets/icons/clip-purple.svg'),
                    alt: "Link"
                  }, null, 8, _hoisted_16),
                  _createElementVNode("a", {
                    onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.sendDataLayer('cv'))),
                    href: _ctx.talent?.curriculum,
                    target: "_blank",
                    class: "link cv"
                  }, _toDisplayString(_ctx.$t('talents.profile.information.cv', { name: _ctx.talent?.name})), 9, _hoisted_17)
                ]))
              : _createCommentVNode("", true)
          ], 2)
        ])
      ])
    ])
  ]))
}