
import { defineComponent, onMounted, ref } from 'vue'
import AccordionFAQS from '@/components/faqs/AccordionFAQS.vue'
import TypeWriter from '@/components/home/TypeWriter.vue'
import Tutorial from '@/components/home/tutorial/Tutorial.vue'
import Register from '@/components/auth/register/RegisterForm.vue'
import useFindFaqs from '@/hooks/useFindFaqs'
import Footer from '@/components/commons/Footer.vue'
import LoadingScreen from '@/components/commons/LoadingScreen.vue'
import { useI18n } from 'vue-i18n'
import { useLocaleStore } from '@/store/modules/translations/locale.store'

export default defineComponent({
  name: 'Home',
  props: ['goRegister'],
  components: {
    AccordionFAQS,
    TypeWriter,
    Tutorial,
    Register,
    Footer,
    LoadingScreen
  },
  setup (props) {
    const localeStore = useLocaleStore()
    const { t } = useI18n({ useScope: 'global' })
    const { faqs, loadingFaqs } = useFindFaqs()
    const active = ref(false)
    const words = ref(['digitales', 'tech', 'profesionales'])

    const getWordsFromLocale = () => {
      words.value = [t('home.typewritter.firstWord'), t('home.typewritter.secondWord'), t('home.typewritter.thirdWord')]
    }

    onMounted(async () => {
      const registerComponent = window.document.getElementById('register')

      if (registerComponent && props.goRegister) {
        registerComponent.scrollIntoView()
      }
      await localeStore.fetchLocaleByIp()
      getWordsFromLocale()
    })

    return {
      faqs,
      loadingFaqs,
      active,
      words,
      getWordsFromLocale
    }
  }
})
