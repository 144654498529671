import usersApi from '@/api/users.api'
import axios from 'axios'
import { useAuth } from '@/auth/useAuthService'

import { defineStore } from 'pinia'//
import router from '@/router'

const notLoggedUser: ApplicationUser = {
  id: '',
  name: '',
  country: '',
  email: '',
  avatar: ''
}

const missingApiToken = ''

const CurrentUserKey = 'current-user'
const TokenKey = 'current-token'

function getStoredCurrentUser (): ApplicationUser | undefined {
  const userJson = localStorage.getItem(CurrentUserKey)
  return userJson ? (JSON.parse(userJson) as ApplicationUser) : undefined
}

function getStoredToken (): string | undefined {
  const token = localStorage.getItem(TokenKey)
  return token || undefined
}

function updateAxios (apiToken: string) {
  if (apiToken) {
    axios.defaults.headers.common.Authorization = `Bearer ${apiToken}`
  } else {
    delete axios.defaults.headers.common.Authorization
  }
}

//
export const useUserSessionStore = defineStore({
  // unique id of the store across your application
  id: 'user-session',
  state () {
    const userSession: UserSessionState = {
      currentUser: getStoredCurrentUser() || notLoggedUser,
      apiToken: getStoredToken() || missingApiToken,
      loginError: undefined,
      forceChangePassword: false,
      tempUser: {
        username: '',
        password: ''
      }
    }

    updateAxios(userSession.apiToken)

    return userSession
  },
  getters: {
    isAuthenticated (): boolean {
      return !!this.apiToken
    },
    getUser (): ApplicationUser {
      return this.currentUser
    },
    getTempUsername (): TempUser {
      return this.tempUser
    }
  },
  actions: {
    clearTempUser () {
      this.forceChangePassword = false
      this.tempUser = {
        username: '',
        password: ''
      }
    },
    socialAccess (user: any, token: string) {
      try {
        this.loginError = undefined
        this.forceChangePassword = false
        this.tempUser = {
          username: '',
          password: ''
        }

        const { name, email, picture: avatar, sub: id } = user

        this.currentUser = {
          id,
          name,
          country: '',
          email,
          avatar
        }

        this.apiToken = token

        updateAxios(this.apiToken)

        localStorage.setItem(CurrentUserKey, JSON.stringify(this.currentUser))
        localStorage.setItem(TokenKey, token)
      } catch (err) {
        console.log(`Login failed with: ${err}`)
        this.loginError = err
        this.apiToken = missingApiToken
      }
    },
    async login (userName: string, password: string) {
      try {
        this.loginError = undefined

        const data = await usersApi.login(userName, password)
        if (data.changePassword) {
          this.forceChangePassword = true
          this.tempUser = data.tempUserData
          return
        } else {
          this.clearTempUser()
        }
        const { id, access_token, name, email, avatar } = data

        this.currentUser = {
          id,
          name,
          country: '',
          email,
          avatar
        }

        this.apiToken = access_token

        updateAxios(this.apiToken)

        localStorage.setItem(CurrentUserKey, JSON.stringify(this.currentUser))
        localStorage.setItem(TokenKey, access_token)
      } catch (err) {
        console.log(`Login failed with: ${err}`)
        this.loginError = err
        this.apiToken = missingApiToken
      }
    },

    async logout () {
      const { logout } = useAuth()
      // await logout()
      localStorage.removeItem(CurrentUserKey)
      localStorage.removeItem(TokenKey)
      this.apiToken = missingApiToken
      this.loginError = undefined
      router.push({ name: 'Login' })
    }
  }
})
