import { createApp } from 'vue'
import axios from 'axios'
import App from './App.vue'
import router from './router'
import PrimeVue from 'primevue/config'
import ToastService from 'primevue/toastservice'
import ConfirmationService from 'primevue/confirmationservice'
import { Amplify, Auth } from 'aws-amplify'
import awsConfig from './config/aws-config'
import { registerCommonComponents } from './commonComponents'
import { createPinia } from 'pinia'
import 'primevue/resources/themes/saga-blue/theme.css' // theme
import 'primevue/resources/primevue.min.css' // core css
import 'primeflex/primeflex.css' // Support for Flex
import 'primeicons/primeicons.css' // icons
import '@/assets/ch-common.css'
import '@/assets/ch-core'
import '@/assets/styles/styles.scss'
import { useI18nStore } from './store/modules/translations/i18n.store'
import { useLocaleStore } from './store/modules/translations/locale.store'
const {
  hostname,
  port,
  protocol
} = window.location

console.log('process: ', process.env)

// El acceso al API se a través de un proxy en el mismo servicio.
const baseUrl = `${protocol}//${hostname}${port ? ':' + port : ''}/api`

declare global {
  interface Window {
    dataLayer?: any;
  }
}

window.dataLayer = window.dataLayer || []

console.log(`Connecting to API @ ${baseUrl} `)

// axios.defaults.baseURL = baseUrl

axios.defaults.withCredentials = false // Set this to false until we use cookies to handle session against the ch-api

// TODO mover !!
// Add a response interceptor
axios.interceptors.response.use(function (response) {
  // Any status code that lie within the range of 2xx cause this function to trigger
  // Do something with response data
  return response
}, function (error) {
  // Any status codes that falls outside the range of 2xx cause this function to trigger
  // Do something with response error
  return Promise.reject(error)
})

Amplify.configure({
  Auth: awsConfig
})
Auth.configure(awsConfig)

const app = createApp(App)
  .use(createPinia())
  .use(router)
  .use(PrimeVue)
  .use(ToastService)
  .use(ConfirmationService)
  .use(useI18nStore().getI18n)
// Vue.config.devtools = true
const localeStore = useLocaleStore()
localeStore.fetchLocaleByIp()

registerCommonComponents(app)

app.mount('#app')
