<template>
<div class="container container-show-filters p-mb-5">
  <div class="show-filters-container">
    <button @click="changeViewFilters" class="p-d-block ch-button-grey ch-buttom-custom" :src="require('@/assets/icons/trash.svg')" alt="Delete filters">
      <i class="pi pi-sliders-h" /> <label for="">{{ $t('talents.filters.buttons.filters') }}</label>
    </button>
  </div>
  <div class="p-d-sm-flex p-jc-center filter-container" v-if="showFilters">
    <div class="p-col-12 p-text-center close-container">
      <button @click="changeViewFilters" class="p-d-block ch-button-grey ch-buttom-custom btn-close" :src="require('@/assets/icons/trash.svg')" alt="Delete filters">
        <i class="pi pi-times" />
      </button>
    </div>

    <div class="p-col-12 p-text-center p-mt-3 p-pr-0 p-pl-0 p-py-0">
      <div class="p-col-12 p-d-sm-flex">
        <span class="p-input-icon-left">
          <i class="pi pi-search" />
          <InputText class="items-search" type="text" v-model="keyValue" @keyup="changeKeyValue" :placeholder="$t('talents.filters.search')" />
        </span>
      </div>
    </div>

    <div class="p-col-12 p-d-sm-flex p-px-0 clear-container">
      <div class="p-col-6 p-lg-8 p-md-8 p-sm-8  p-d-md-flex p-ai-center ">
        {{ $t('talents.filters.text') }}
      </div>
      <div class="p-d-md-flex p-text-center pointerc p-col-6 p-lg-4 p-md-4 p-sm-4">
        <button @click="deleteFilter" class="p-d-block ch-button-grey ch-buttom-custom delete-button" :src="require('@/assets/icons/trash.svg')" alt="Delete filters">
          {{ $t('talents.filters.buttons.delete') }}
        </button>
      </div>
    </div>
    <div class="p-col-12 p-text-center p-pr-0 p-pl-0 p-py-0">
      <FilterCourse ref="opCourses" />
    </div>
    <div class="p-col-12 p-text-center p-pr-0 p-pl-0 p-py-0">
      <FilterLanguage ref="opLanguages" />
    </div>
    <div class="p-col-12 p-text-center p-pr-0 p-pl-0 p-py-0">
       <FilterSkill ref="opSkills"/>
    </div>
    <div class="p-col-12 p-text-center p-pr-0 p-pl-0 p-py-0">
       <FilterContractType ref="opContracts" />
    </div>
    <div class="p-col-12 p-text-center p-pr-0 p-pl-0 p-py-0">
      <FilterLocation ref="opLocations" />
    </div>
    <div class="p-col-12 p-text-center p-pr-0 p-pl-0 p-py-0">
      <div class="p-d-md-flex p-text-center pointerc p-col-12">
        <button @click="searchTalents()" class="p-d-block ch-button-grey ch-buttom-custom" :src="require('@/assets/icons/trash.svg')" alt="Delete filters">
          {{ $t('talents.filters.buttons.search') }}
        </button>
      </div>
    </div>
  </div>
</div>

</template>

<script src="./FilterTalent.ts" ></script>
<style scoped lang="scss" src="./FilterTalent.scss"></style>
