import Avatar from '@/components/avatar/Avatar'
import { defineComponent, PropType } from 'vue'
import { RouteNames } from '@/router/route-names'
import { SocialNetworks } from '@/models/talents'

export default defineComponent({
  name: 'AvatarTalent',
  components: {
    Avatar
  },
  props: {
    talentId: {
      required: true,
      type: String
    },
    name: {
      required: true,
      type: String
    },
    avatar: {
      required: false,
      type: String
    },
    socialNetworks: {
      required: false,
      type: Object as PropType<SocialNetworks>
    },
    background: {
      required: false,
      type: String
    },
    email: {
      required: false,
      type: String
    }
  },
  setup () {
    return {
      RouteNames
    }
  }
})
