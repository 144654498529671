import { defineStore } from 'pinia'
import { createI18n } from 'vue-i18n'

export const useI18nStore = defineStore({
  id: 'i18n',
  state: () => ({
    i18n: createI18n({
      legacy: false,
      fallbackLocale: 'es',
      globalInjection: true,
      missingWarn: false,
      fallbackWarn: false
    })
  }),
  getters: {
    getI18n (): any {
      return this.i18n
    }
  },
  actions: {
    setLocaleMessages  (locale: string, message: Record<string, string>) {
      this.i18n.global.setLocaleMessage(locale, message)
    },
    setLocale (locale: string) {
      this.i18n.global.locale = locale
    }
  }
})
