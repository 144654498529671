import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-5d593d64"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "main-container" }
const _hoisted_2 = { class: "form-container" }
const _hoisted_3 = { class: "password-info-container" }
const _hoisted_4 = { class: "input-container" }
const _hoisted_5 = { for: "password" }
const _hoisted_6 = {
  key: 0,
  class: "empty-div"
}
const _hoisted_7 = {
  key: 1,
  class: "form-error"
}
const _hoisted_8 = {
  key: 2,
  class: "form-error"
}
const _hoisted_9 = {
  key: 3,
  class: "form-error"
}
const _hoisted_10 = {
  key: 4,
  class: "form-error"
}
const _hoisted_11 = {
  key: 5,
  class: "form-error"
}
const _hoisted_12 = {
  key: 6,
  class: "form-error"
}
const _hoisted_13 = { class: "input-container" }
const _hoisted_14 = { for: "password" }
const _hoisted_15 = {
  key: 7,
  class: "empty-div"
}
const _hoisted_16 = {
  key: 8,
  class: "form-error"
}
const _hoisted_17 = {
  key: 9,
  class: "form-error"
}
const _hoisted_18 = {
  key: 10,
  class: "form-error"
}
const _hoisted_19 = {
  key: 11,
  class: "form-error"
}
const _hoisted_20 = {
  key: 12,
  class: "form-error"
}
const _hoisted_21 = {
  key: 13,
  class: "form-error"
}
const _hoisted_22 = {
  key: 14,
  class: "form-error"
}
const _hoisted_23 = {
  key: 15,
  class: "form-error"
}
const _hoisted_24 = { class: "p-dialog-footer p-col-12" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_InputText = _resolveComponent("InputText")!
  const _component_Button = _resolveComponent("Button")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("p", null, _toDisplayString(_ctx.$t('changePasswordForm.info')), 1),
        _createElementVNode("ul", null, [
          _createElementVNode("li", null, _toDisplayString(_ctx.$t('changePasswordForm.info.length')), 1),
          _createElementVNode("li", null, _toDisplayString(_ctx.$t('changePasswordForm.info.number')), 1),
          _createElementVNode("li", null, _toDisplayString(_ctx.$t('changePasswordForm.info.uppercase')), 1),
          _createElementVNode("li", null, _toDisplayString(_ctx.$t('changePasswordForm.info.lowercase')), 1),
          _createElementVNode("li", null, _toDisplayString(_ctx.$t('changePasswordForm.info.specialCharacters')), 1)
        ])
      ]),
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("label", _hoisted_5, _toDisplayString(_ctx.$t('changePasswordForm.newPassword.label')), 1),
        _createVNode(_component_InputText, {
          type: "password",
          class: "p-inputtext-lg dark-input p-my-1",
          modelValue: _ctx.v$.password.$model,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.v$.password.$model) = $event)),
          onChange: _ctx.inputDetected
        }, null, 8, ["modelValue", "onChange"])
      ]),
      (!_ctx.v$.password.$errors[0])
        ? (_openBlock(), _createElementBlock("div", _hoisted_6))
        : _createCommentVNode("", true),
      (_ctx.v$.password.$errors[0]?.$validator == 'required')
        ? (_openBlock(), _createElementBlock("div", _hoisted_7, _toDisplayString(_ctx.$t('registerForm.alerts.emptyField')), 1))
        : _createCommentVNode("", true),
      (_ctx.v$.password.$errors[0]?.$validator == 'minLengthValue')
        ? (_openBlock(), _createElementBlock("div", _hoisted_8, _toDisplayString(_ctx.$t('changePasswordForm.errors.length')), 1))
        : _createCommentVNode("", true),
      (_ctx.v$.password.$errors[0]?.$validator == 'uppercase')
        ? (_openBlock(), _createElementBlock("div", _hoisted_9, _toDisplayString(_ctx.$t('changePasswordForm.errors.uppercase')), 1))
        : _createCommentVNode("", true),
      (_ctx.v$.password.$errors[0]?.$validator == 'lowercase')
        ? (_openBlock(), _createElementBlock("div", _hoisted_10, _toDisplayString(_ctx.$t('changePasswordForm.errors.lowercase')), 1))
        : _createCommentVNode("", true),
      (_ctx.v$.password.$errors[0]?.$validator == 'number')
        ? (_openBlock(), _createElementBlock("div", _hoisted_11, _toDisplayString(_ctx.$t('changePasswordForm.errors.number')), 1))
        : _createCommentVNode("", true),
      (_ctx.v$.password.$errors[0]?.$validator == 'specialCharacters')
        ? (_openBlock(), _createElementBlock("div", _hoisted_12, _toDisplayString(_ctx.$t('changePasswordForm.errors.specialCharacters')), 1))
        : _createCommentVNode("", true),
      _createElementVNode("div", _hoisted_13, [
        _createElementVNode("label", _hoisted_14, _toDisplayString(_ctx.$t('changePasswordForm.confirmNewPassword.label')), 1),
        _createVNode(_component_InputText, {
          type: "password",
          class: "p-inputtext-lg dark-input p-my-1",
          modelValue: _ctx.v$.newPassword.$model,
          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.v$.newPassword.$model) = $event)),
          onChange: _ctx.inputDetected
        }, null, 8, ["modelValue", "onChange"])
      ]),
      (!_ctx.v$.newPassword.$errors[0])
        ? (_openBlock(), _createElementBlock("div", _hoisted_15))
        : _createCommentVNode("", true),
      (_ctx.v$.newPassword.$errors[0]?.$validator == 'required')
        ? (_openBlock(), _createElementBlock("div", _hoisted_16, _toDisplayString(_ctx.$t('registerForm.alerts.emptyField')), 1))
        : _createCommentVNode("", true),
      (_ctx.v$.newPassword.$errors[0]?.$validator == 'minLengthValue')
        ? (_openBlock(), _createElementBlock("div", _hoisted_17, _toDisplayString(_ctx.$t('changePasswordForm.errors.length')), 1))
        : _createCommentVNode("", true),
      (_ctx.v$.newPassword.$errors[0]?.$validator == 'uppercase')
        ? (_openBlock(), _createElementBlock("div", _hoisted_18, _toDisplayString(_ctx.$t('changePasswordForm.errors.uppercase')), 1))
        : _createCommentVNode("", true),
      (_ctx.v$.newPassword.$errors[0]?.$validator == 'lowercase')
        ? (_openBlock(), _createElementBlock("div", _hoisted_19, _toDisplayString(_ctx.$t('changePasswordForm.errors.lowercase')), 1))
        : _createCommentVNode("", true),
      (_ctx.v$.newPassword.$errors[0]?.$validator == 'number')
        ? (_openBlock(), _createElementBlock("div", _hoisted_20, _toDisplayString(_ctx.$t('changePasswordForm.errors.number')), 1))
        : _createCommentVNode("", true),
      (_ctx.v$.newPassword.$errors[0]?.$validator == 'specialCharacters')
        ? (_openBlock(), _createElementBlock("div", _hoisted_21, _toDisplayString(_ctx.$t('changePasswordForm.errors.specialCharacters')), 1))
        : _createCommentVNode("", true),
      (_ctx.weakPassword)
        ? (_openBlock(), _createElementBlock("div", _hoisted_22, _toDisplayString(_ctx.$t("registerForm.alerts.invalidPassword")), 1))
        : _createCommentVNode("", true),
      (_ctx.passwordAreDifferents)
        ? (_openBlock(), _createElementBlock("div", _hoisted_23, _toDisplayString(_ctx.$t('changePasswordForm.errors.notMatch')), 1))
        : _createCommentVNode("", true),
      _createElementVNode("div", _hoisted_24, [
        _createVNode(_component_Button, {
          label: _ctx.isLoading ? '' : _ctx.$t('changePasswordForm.buttons.confirm'),
          class: "send-button",
          loading: _ctx.isLoading,
          onClick: _ctx.submit
        }, null, 8, ["label", "loading", "onClick"])
      ])
    ])
  ]))
}