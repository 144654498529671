import { Talent } from '@/models/talents'

export default function SocialNetworksOrder (talent: Talent) {
  const getOrderedSocialNetworks = (max = 10) => {
    const talentSocialNetworks: any = talent?.socialNetworks ?? null
    const results: any = {}
    const socialNetworks = ['linkedin', 'behance', 'github', 'youtube', 'dribble', 'vimeo', 'instagram', 'facebook', 'twitter', 'website']
    let counter = 0
    for (let i = 0; i <= socialNetworks.length && counter <= max; i++) {
      if (talentSocialNetworks?.[socialNetworks[i]]) {
        results[socialNetworks[i]] = talentSocialNetworks[socialNetworks[i]]
        counter++
      }
    }
    return results
  }

  return {
    getOrderedSocialNetworks
  }
}
