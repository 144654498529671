
import { defineComponent, reactive, ref } from 'vue'
import { required, email } from '@vuelidate/validators'
import useVuelidate from '@vuelidate/core'
import { useUserSessionStore } from '@/store/modules/commons/user-session-store'
import { useAuth } from '@/auth/useAuthService'
import { useRouter } from 'vue-router'
import Dialog from 'primevue/dialog'
import ChangePassword from '@/views/auth/ChangePassword.vue'

export default defineComponent({
  name: 'Login',
  components: {
    Dialog,
    ChangePassword
  },
  props: {
    error: [String, Error]
  },

  emits: ['onSubmit'],

  setup () {
    const state = reactive({
      name: '',
      email: '',
      password: '',
      showPassword: false,
      country: '',
      termsConditions: false,
      userType: '',
      loginFailed: false
    })
    const { loginWithRedirect } = useAuth()
    const accessWith = (socialNetworkName: string) => loginWithRedirect({ connection: socialNetworkName, redirect_uri: window.location.href })

    const router = useRouter()
    const errorMail = ref(false)
    const isLoading = ref(false)
    const showRecoveryPassword = ref(false)

    const rules = {
      email: { required, email },
      password: { required },
      showPassword: { required }
    }

    const v$ = useVuelidate(rules, state)
    const sessionStore = useUserSessionStore()

    const submit = async () => {
      state.loginFailed = false
      v$.value.$touch()
      const isFormCorrect = await v$.value.$validate()

      if (!isFormCorrect) return

      isLoading.value = true

      await sessionStore.login(state.email, state.password)
      if (sessionStore.forceChangePassword) {
        state.email = ''
        state.password = ''
        v$.value.$reset()
        showRecoveryPassword.value = true
      }
      if (sessionStore.loginError) state.loginFailed = true
      if (sessionStore.isAuthenticated) router.push('talents')

      isLoading.value = false
      errorMail.value = true
    }

    const toggleError = () => {
      v$.value.email.$reset()
      errorMail.value = false
      state.loginFailed = false
    }

    const toggleErrorAuth0 = () => {
      state.loginFailed = false
    }

    const closeModal = () => {
      showRecoveryPassword.value = false
    }
    return {
      state,
      submit,
      v$,
      errorMail,
      accessWith,
      toggleError,
      toggleErrorAuth0,
      isLoading,
      showRecoveryPassword,
      closeModal
    }
  }
})
