
<template>
<div class="p-px-3 container__description">

  <div class="container__description__text ">
    <div class="description general__item">
      <p class="p-mt-0  description--text">{{ presentation }}</p>
    </div>

    <div class="skills p-mt-4">
      <span v-for="(skill, i) of skills" :key="skill.code">
        <span class="skill p-mr-4" v-if="i<=5">{{ $t(skill.name) }}</span>
      </span>
    </div>
  </div>
</div>
</template>

<script src="./DescriptionTalent.ts"></script>
<style scoped lang="scss" src="./DescriptionTalent.scss"></style>
