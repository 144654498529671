
<template>
  <div class="container">
    <div class="avatar p-mb-2 p-mt-1">
      <Avatar size="large" :image="avatar || require('@/assets/icons/avatar.png')" shape="circle" />
    </div>
    <span class="p-mb-1 name-container">
      <label class="name">{{name}}</label>
      <div class="networks-container" v-if="socialNetworks.linkedin">
        <img class="p-d-block social-icon"  :href="socialNetworks.linkedin" :src="require(`@/assets/icons/linkedin.svg`)" shape="circle" :alt="socialNetworks.linkedin"/>
      </div>
    </span>
    <div class="rol p-mb-3 background">{{$t(background)}}</div>
  </div>
</template>

<script src="./AvatarTalent.ts"></script>
<style scoped lang="scss" src="./AvatarTalent.scss"></style>
