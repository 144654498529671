const chCore = {
  domain: `${process.env.CORE_URL}/`,
  build (files: Array<string>, ext = 'js') {
    return files.map(file => `${this.domain}${file}.${ext}`)
  },
  // ------------------------------------------
  js () {
    return null
  },
  css () {
    return this.build([
      'ch-ui-kit-common',
      'ch-fonts'
    ], 'css')
  },
  all () {
    return {
      js: this.js(),
      css: this.css()
    }
  }
}

const links = chCore.all()

links.css.forEach(url => {
  const linkEl = document.createElement('link')
  linkEl.setAttribute('rel', 'stylesheet')
  linkEl.setAttribute('href', url)
  document.head.appendChild(linkEl)
})
