
import { defineComponent, reactive, ref } from 'vue'
import RadioButton from 'primevue/radiobutton'
import { required, email } from '@vuelidate/validators'
import useVuelidate from '@vuelidate/core'
import { useAuth } from '@/auth/useAuthService'
import usersApi from '@/api/users.api'

export default defineComponent({
  name: 'Register',
  props: {
    error: [String, Error]
  },
  components: {
    RadioButton
  },

  setup () {
    const state = reactive({
      name: '',
      email: '',
      password: '',
      showPassword: false,
      country: '',
      termsConditions: false,
      userType: '',
      weakPassword: false,
      alreadyInUse: false
    })

    const registerSuccess = ref(false)
    const uncheckedTerms = ref(false)
    const lastEmail = ref('')
    const isLoading = ref(false)

    const mustBeTrue = (value: boolean) => !!value

    const rules = {
      name: { required },
      email: { required, email },
      password: { required },
      showPassword: { required },
      country: { required },
      termsConditions: { required, mustBeTrue },
      userType: { required }
    }

    const v$ = useVuelidate(rules, state)

    const submit = async () => {
      v$.value.$touch()
      const isFormCorrect = await v$.value.$validate()

      if (!isFormCorrect) return

      try {
        isLoading.value = true
        lastEmail.value = ''
        await usersApi.register(state.email, state.password, state.name, state.country, state.userType)
        lastEmail.value = state.email
        registerSuccess.value = true
        state.weakPassword = false
        state.alreadyInUse = false

        v$.value.$reset()
        state.name = ''
        state.name = ''
        state.email = ''
        state.password = ''
        state.showPassword = false
        state.country = ''
        state.termsConditions = false
        state.userType = ''

        isLoading.value = false

        window.dataLayer.push({
          event: 'trackEvent',
          eventCategory: 'codertalent',
          eventAction: 'registro',
          eventLabel: 'registro completado'
        })
      } catch (error) {
        const { message } = error
        switch (message) {
          case 'Password is too weak':
            state.weakPassword = true
            isLoading.value = false
            break
          case 'Request failed with status code 409':
            state.alreadyInUse = true
            isLoading.value = false
            break
        }
      }
    }

    const resetValidate = () => {
      v$.value.email.$reset()
    }

    const closeSuccessMsg = () => {
      registerSuccess.value = false
    }

    const { loginWithRedirect } = useAuth()
    const accessWith = (socialNetworkName: string) => {
      uncheckedTerms.value = false
      if (state.termsConditions) {
        loginWithRedirect({ connection: socialNetworkName })
      } else {
        uncheckedTerms.value = true
      }
    }

    return {
      state,
      submit,
      v$,
      registerSuccess,
      closeSuccessMsg,
      accessWith,
      resetValidate,
      uncheckedTerms,
      lastEmail,
      isLoading
    }
  }
})
