export interface ILanguage {
    code: string;
    name: string;
  }

export interface ICurrency {
    id: string;
    name: string;
    symbol: string;
    isoCode: string;
    decimals: string;
    symbolPosition: string;
    priceByHour: number;
    dollarExchange: number;
    createdAt: Date;
    updatedAt: Date;
    __v: number;
  }

export interface ICountry {
    id: string;
    name: string;
    code: string;
    timezone: string;
    currency: ICurrency;
    enable: boolean;
    createdAt: Date;
    updatedAt: Date;
    __v: number;
  }

export interface ILocale {
    locale: string;
    baseLocale: string;
    language: ILanguage;
    country: ICountry;
    version: number;
    updatedAt: Date;
    translations: any;
  }

export class Locale {
    locale: string
    baseLocale: string
    translations: any
    constructor (from: ILocale) {
    	this.locale = from.locale
    	this.baseLocale = from.baseLocale
    	this.translations = from.translations
    }
}
