
<template>
<div class="p-d-flex p-flex-wrap filter-content">
    <div class="grade-filter p-col-12 p-lg-12">
      <h3 class="filter-title">{{ $t('talents.filters.contracType') }}</h3>
      <div class="p-grid p-d-flex p-flex-wrap p-mt-2">
        <div class="p-field-checkbox p-m-0  p-col-6 p-md-6 p-sm-12" v-for="contractType in contractsType" :key="contractType">
            <Checkbox
              class="ch-checkbox"
              v-model="selectedContractType"
              :id="contractType"
              :name="contractType"
              :value="contractType"
            />
            <label class="text" :for="contractType">{{ $t(contractType) }}</label>
          </div>
      </div>
    </div>
  </div>
</template>

<script src="./FilterContractType.ts" ></script>
<style scoped lang="scss" src="./FilterContractType.scss"></style>
