

import { defineComponent, ref, onMounted, Ref } from 'vue'
import { Talent, FinalAssignment, SocialNetworks } from '@/models/talents'
import { useToast } from 'primevue/usetoast'
import { ToastLife, ToastSeverities, ToastSummaries } from '@/models/primevue/toast'
import Tooltip from 'primevue/tooltip'
import finalAssignmentsFilter from '@/components/talent/utils/FinalAssignmentsFilter'
import SocialNetworksOrder from '../../utils/SocialNetworksOrder'
import ButtonContactar from '../../ButtonContactar.vue'

export default defineComponent({
  name: 'PersonalInfo',
  components: {
    ButtonContactar
  },
  directives: {
    tooltip: Tooltip
  },
  props: {
    talent: {
      required: true,
      type: Talent
    },
    goBack: {
      required: true,
      type: Function
    }
  },
  setup (props) {
    const finalAssignments: Ref<Array<FinalAssignment>> = ref([])
    const url = ref('')
    const toast = useToast()

    const { filteredAssignments } = finalAssignmentsFilter(props.talent)

    const socialNetworks: Ref<SocialNetworks[]> = ref([])
    const { getOrderedSocialNetworks } = SocialNetworksOrder(props.talent)

    const copySuccess = () => {
      toast.add({ severity: ToastSeverities.Success, summary: ToastSummaries.Copied, detail: 'URL Copiada!', life: ToastLife.Default })
    }

    const copyFailure = () => {
      toast.add({ severity: ToastSeverities.Error, summary: ToastSummaries.Error, detail: 'Ocurrio un error al copiar la URL!', life: ToastLife.Default })
    }

    const copyToClipboard = () => {
      navigator.clipboard.writeText(`${url.value}`).then(copySuccess, copyFailure)
    }

    const getURL = () => {
      url.value = window.location.href
    }

    const sendDataLayer = (type: string) => {
      window.dataLayer.push({
        event: 'trackEvent',
        eventCategory: 'codertalent',
        eventAction: 'descarga',
        eventLabel: `${type}`
      })
    }

    onMounted(getURL)
    onMounted(() => {
      finalAssignments.value = filteredAssignments()
      socialNetworks.value = getOrderedSocialNetworks()
    })

    return { finalAssignments, copyToClipboard, socialNetworks, sendDataLayer }
  }

})
