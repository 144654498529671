
import { defineComponent, ref } from 'vue'
import Accordion from 'primevue/accordion'
import AccordionTab from 'primevue/accordiontab'
import Dropdown from 'primevue/dropdown'
import { useLocaleStore } from '../../store/modules/translations/locale.store'

export default defineComponent({
  components: {
    Accordion,
    AccordionTab,
    Dropdown
  },
  emits: ['localeRetrieved'],
  setup (props, ctx) {
    const localeStore = useLocaleStore()
    const currentYear = new Date().getFullYear()
    const selectedCountry = ref({ name: 'Argentina', code: 'AR', flag: '/assets/flags/ARG.svg' })
    const countries = [
      { name: 'Argentina', code: 'es-AR', flag: '/assets/flags/ARG.svg' },
      { name: 'Brasil', code: 'pt-BR', flag: '/assets/flags/BRA.svg' },
      { name: 'Chile', code: 'es-ES', flag: '/assets/flags/CHL.svg' },
      { name: 'Colombia', code: 'es-ES', flag: '/assets/flags/COL.svg' },
      { name: 'España', code: 'es-ES', flag: '/assets/flags/ESP.svg' },
      { name: 'Estados Unidos', code: 'en-US', flag: '/assets/flags/EUA.svg' },
      { name: 'Mexico', code: 'es-MX', flag: '/assets/flags/MEX.svg' },
      { name: 'Otro', code: 'es-AR', flag: '/assets/flags/otro.svg' },
      { name: 'Peru', code: 'es-ES', flag: '/assets/flags/PER.svg' },
      { name: 'Uruguay', code: 'es-AR', flag: '/assets/flags/URU.svg' }
    ]

    const changeCountry = async (event: any) => {
      await localeStore.fetchLocale(event.value.code)
      ctx.emit('localeRetrieved')
    }

    return { countries, selectedCountry, currentYear, changeCountry }
  }
})
