import { useUserSessionStore } from '@/store/modules/commons/user-session-store'
import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import { h, resolveComponent } from 'vue'
import Login from '@/views/auth/Login.vue'
import Register from '@/views/auth/Register.vue'
import ValidationRedirect from '@/views/auth/ValidationRedirect.vue'
import Home from '@/views/Home/Home.vue'
import Layout from '@/layouts/Layout.vue'
import TalentRoutes from '../views/talent/routes'
import Terms from '@/views/Terms.vue'
import PasswordRecovery from '@/views/auth/PasswordRecovery.vue'
import ChangePassword from '@/views/auth/ChangePassword.vue'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/login',
    name: 'Login',
    component: Login,
    meta: {
      guest: true
    }
  },
  {
    path: '/register',
    name: 'Register',
    component: Register,
    meta: {
      guest: true
    }
  },
  {
    path: '/validation-redirect',
    name: 'ValidationRedirect',
    component: ValidationRedirect,
    meta: {
      guest: true
    }
  },
  {
    path: '/',
    name: 'Layout',
    component: Layout,
    children: [
      {
        path: '/home',
        name: 'Home',
        component: Home,
        meta: {
          guest: true
        },
        props: true
      },
      {
        path: 'talents',
        name: 'Talents',
        component: {
          name: 'TalentsRoutes',
          render: () => h(resolveComponent('router-view'))
        },
        children: TalentRoutes
      },
      {
        path: '',
        redirect: { path: '/home' }
      },
      {
        path: '/legales-coder-talento',
        name: 'Terms',
        component: Terms,
        meta: {
          guest: true
        }
      },
      {
        path: '/password-recovery',
        name: 'PasswordRecovery',
        component: PasswordRecovery,
        meta: {
          guest: true
        }
      },
      {
        path: '/change-password',
        name: 'ChangePassword',
        component: ChangePassword,
        meta: {
          guest: true
        }
      }
    ]
  },
  {
    path: '/:notFound(.*)',
    redirect: '/home'
  },
  {
    path: '/logout',
    redirect: '/login'
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) => {
  const session = useUserSessionStore()

  if (to.matched.some(record => record.meta.guest)) {
    if (to.name === 'Login' && session.isAuthenticated) {
      next({
        path: '/talents'
      })
      return
    }
    next()
  } else if (session.isAuthenticated) {
    next()
  } else {
    next({
      name: 'Login',
      params: { nextUrl: to.fullPath }
    })
  }
})

export default router
