import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-a726ed3e"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 1,
  class: "validation-container"
}
const _hoisted_2 = { class: "p-d-flex p-ai-center p-jc-between module-header p-px-3 p-px-md-6" }
const _hoisted_3 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_LoadingScreen = _resolveComponent("LoadingScreen")!
  const _component_Confirmated = _resolveComponent("Confirmated")!
  const _component_Expired = _resolveComponent("Expired")!

  return (_ctx.loadingVerifications)
    ? (_openBlock(), _createBlock(_component_LoadingScreen, { key: 0 }))
    : (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("header", _hoisted_2, [
          _createElementVNode("img", {
            class: "logo",
            src: require('@/assets/coderhouse.svg'),
            alt: "Coder House"
          }, null, 8, _hoisted_3)
        ]),
        (_ctx.response)
          ? (_openBlock(), _createBlock(_component_Confirmated, { key: 0 }))
          : (_openBlock(), _createBlock(_component_Expired, { key: 1 }))
      ]))
}