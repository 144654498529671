<template>
	<div class="main" ref="wordContainer">
		<transition-group name="list" tag="p" class="transition-container" :style="style">
			<div :class="{underlineBox: pausedAnimation, underlineBoxIn: pausedAnimation && isDeleting, underlineBoxOut: isDeleting && !pausedAnimation}" key="underline"></div>
			<span v-for="letter in word" :key="letter" class="list-letter">
			{{ letter }}
			</span>
		</transition-group>
	</div>
</template>

<script>
import { defineComponent, ref, onMounted } from 'vue'

export default defineComponent({
  name: 'typeWriter',
  props: {
    words: {
      required: true,
      type: Array
    },
    wait: {
      required: true,
      type: Number
    }
  },
  setup (props) {
    const word = ref([])
    const wordContainer = ref(null)
    const style = ref('')
    const pausedAnimation = ref(false)
    const isDeleting = ref(false)

    const time = 250
    const pause = 1500
    let typeSpeed = 10
    let wordIndex = 0
    let writeIndex = 0
    let currentWidth = 'auto'

    const type = () => {
      pausedAnimation.value = false

      const current = wordIndex % props.words.length
      const completeWord = props.words[current].split('')

      typeSpeed = time / completeWord.length

      if (isDeleting.value) {
        if (word.value.length === completeWord.length) {
          currentWidth = getComputedStyle(wordContainer.value).width
          style.value = `width: ${currentWidth};`
        }

        word.value.shift()
      } else {
        style.value = 'width: auto;'
        word.value.push(completeWord[writeIndex])
        writeIndex++
      }

      if (!isDeleting.value && word.value.length === completeWord.length) {
        pausedAnimation.value = true
        isDeleting.value = true
        typeSpeed = pause
      } else if (isDeleting.value && word.value.length === 0) {
        isDeleting.value = false
        wordIndex++
        writeIndex = 0
        typeSpeed = props.wait
      }

      setTimeout(() => type(), typeSpeed)
    }

    onMounted(type)

    return {
      word,
      style,
      wordContainer,
      pausedAnimation,
      isDeleting
    }
  }
})
</script>

<style lang="scss" scoped>
	.main {
		display: flex;
		align-items: flex-start;
		width: fit-content;
		padding: 0;
		height: 4.5rem;
	}

	.transition-container {
		position: relative;
		display: flex;
		justify-content: flex-end;
		height: 4.5rem;
		margin: 0;
	}

	.underlineBoxIn:after {
		position: absolute;
		display: flex;
		bottom: 10%;
		left: 0;
		z-index: 0;

		content: '';
		justify-content: flex-end;
		display: block;
		margin: auto;
		height: 6px;
		animation: underline 0.25s ease-in;
	}

	@keyframes underline {
		0% { width: 0%; background-color: transparent; }
		100% { width: 100%; background-color: $secondary-color; }
	}

	.underlineBox::after {
		position: absolute;
		display: flex;
		bottom: 10%;
		left: 0;
		z-index: 0;

		content: '';
		justify-content: flex-end;
		display: block;
		margin: auto;
		height: 6px;
		width: 100%;
		background-color: $secondary-color;
	}

	.underlineBoxOut:after {
		position: absolute;
		display: flex;
		bottom: 10%;
		right: 0;
		z-index: 0;

		content: '';
		justify-content: flex-end;
		display: block;
		margin: auto;
		height: 6px;
		animation: underlineOut 0.25s ease-in;
	}

	@keyframes underlineOut {
		0% { width: 90%; background-color: $secondary-color; }
		100% { width: 0%; background-color: transparent; }
	}

	.list-letter {
		position: relative;
		display: inline-block;
		z-index: 10;
	}
	.list-enter-active,
	.list-leave-active {
		transition: all 0.25s ease-in;
	}
	.list-enter-from,
	.list-leave-to {
		opacity: 0;
		transform: scale(0.8);
	}

</style>
