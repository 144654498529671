
import { defineComponent, ref } from 'vue'
import Accordion from 'primevue/accordion'
import AccordionTab from 'primevue/accordiontab'
import Checkbox from 'primevue/checkbox'

export default defineComponent({
  name: 'MultiSelectCheckbox',
  components: {
    Accordion,
    AccordionTab,
    Checkbox
  },
  props: {
    header: {
      type: String,
      default: '',
      required: false
    },
    title: {
      type: String,
      default: '',
      required: false
    },
    updateItems: {
      type: Function,
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      default: () => {},
      required: false
    },
    clearItems: {
      type: Function,
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      default: () => {},
      required: false
    },
    items: {
      type: Object,
      required: false
    }
  },
  data () {
    return {
      activeIndex: [],
      selectedItems: <string[]>([])
    }
  },
  methods: {
    closeTab () {
      this.activeIndex = []
      this.$emit('updateItems', this.selectedItems)
    },
    removeItem (course: string) {
      const index = this.selectedItems.indexOf(course)
      this.selectedItems.splice(index, 1)
      this.$emit('updateItems', this.selectedItems)
    },
    removeItemsSelected () {
      this.selectedItems = []
    }
  }
})
