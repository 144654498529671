import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-21a2ab32"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "education-main-container" }
const _hoisted_2 = { class: "about-me-section__description--text" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_EducationTable = _resolveComponent("EducationTable")!
  const _component_SkillsList = _resolveComponent("SkillsList")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("p", _hoisted_2, _toDisplayString(_ctx.talent?.presentation), 1),
    _createVNode(_component_EducationTable, { courses: _ctx.courses }, null, 8, ["courses"]),
    _createVNode(_component_SkillsList, {
      skills: _ctx.talent?.skills,
      languages: _ctx.talent?.languages
    }, null, 8, ["skills", "languages"])
  ]))
}