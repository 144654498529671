<template>
  <InputItemSearch
    :placeholder="$t('talents.filters.country.search')"
    :title="$t('talents.filters.country')"
    :search="search"
    @update:search="search = $event"
    @searchItems="searchCountry"
    :selectedItems="selectedCountries"
    :itemsList="filteredCoutry"
    @selectItem="selectCountry"
    @removeItem="removeCountry"
  />
</template>
<script  src="./FilterLocation.ts" ></script>
<style scoped lang="scss" src="./FilterLocation.scss"></style>
