import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-714d5780"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "confirmated-container" }
const _hoisted_2 = { class: "confirmated-container__title" }
const _hoisted_3 = { class: "confirmated-container__message" }
const _hoisted_4 = ["src"]
const _hoisted_5 = { class: "p-d-flex w-100 confirmated-container__button" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Button = _resolveComponent("Button")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("p", _hoisted_2, _toDisplayString(_ctx.$t('registerConfirmation.confirmated.title')), 1),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("img", {
        src: require('@/assets/icons/auth/check-circle.svg'),
        alt: "check"
      }, null, 8, _hoisted_4),
      _createElementVNode("p", null, _toDisplayString(_ctx.$t('registerConfirmation.confirmated.msg')), 1)
    ]),
    _createElementVNode("div", _hoisted_5, [
      _createVNode(_component_Button, {
        label: _ctx.$t('registerConfirmation.confirmated.button'),
        class: "p-button-secondary",
        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$router.push({ name: 'Login' })))
      }, null, 8, ["label"])
    ])
  ]))
}