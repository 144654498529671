<template>
  <MultiSelectCheckbox
    :header="$t('talents.filters.areas.select')"
    :title="$t('talents.filters.area')"
    @updateItems="updateCategories"
    :items="categories"
    ref="opCoursesSelector"
  />
</template>

<script src="./FilterCourse.ts"></script>
<style scoped lang="scss" src="./FilterCourse.scss"></style>
