import axios, { AxiosError, AxiosRequestConfig } from 'axios'

const { API_URL } = process.env
console.log('API URL: ', process.env)
export class Api {
  static async request<T> (config: AxiosRequestConfig): Promise<T> {
    try {
      const { data } = await axios(config)
      return data
    } catch (error) {
      if ((error as AxiosError).isAxiosError) {
        const axiosErr = error as AxiosError
        const errorObject = {
          error: axiosErr.response?.data.error,
          code: axiosErr.code || axiosErr.response?.data.statusCode,
          message:
            axiosErr.response?.data.message || axiosErr.response?.statusText,
          config: {
            baseUrl: axiosErr.config.baseURL,
            headers: axiosErr.config.headers,
            url: axiosErr.config.url
          }
        }
        throw errorObject
      } else {
        throw error
      }
    }
  }

  static async get<T> (
    url: string,
    params?: Record<string, string | number | boolean | Array<unknown>>,
    config?: AxiosRequestConfig
  ): Promise<T> {
    return this.request<T>({
      baseURL: API_URL,
      method: 'GET',
      url,
      params,
      ...config
    })
  }

  static async del<T> (
    url: string,
    params?: Record<string, string | number | boolean | Array<unknown>>,
    config?: AxiosRequestConfig
  ): Promise<T> {
    return this.request<T>({
      baseURL: API_URL,
      method: 'DELETE',
      url,
      params,
      ...config
    })
  }

  static async post<T> (
    url: string,
    body: Record<string, string | number | boolean | Array<unknown> | unknown>,
    params?: Record<string, string | number | boolean | Array<unknown>>,
    config?: AxiosRequestConfig
  ): Promise<T> {
    return this.request<T>({
      baseURL: API_URL,
      method: 'POST',
      url,
      params,
      data: body,
      ...config
    })
  }

  static async patch<T> (
    url: string,
    body: Record<string, string | number | boolean | Array<unknown>>,
    params?: Record<string, string | number | boolean | Array<unknown>>,
    config?: AxiosRequestConfig
  ): Promise<T> {
    return this.request<T>({
      baseURL: API_URL,
      method: 'PATCH',
      url,
      params,
      data: body,
      ...config
    })
  }

  static async put<T> (
    url: string,
    body: Record<string, string | number | boolean | Array<unknown>>,
    params?: Record<string, string | number | boolean | Array<unknown>>,
    config?: AxiosRequestConfig
  ): Promise<T> {
    return this.request<T>({
      baseURL: API_URL,
      method: 'PUT',
      url,
      params,
      data: body,
      ...config
    })
  }
}
