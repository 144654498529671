import { Country, Skill } from '@/models/talents'
import { StoreIds } from '@/store/StoreIds'
import { defineStore } from 'pinia'

interface FiltersTalentState {
  keyFilter: string
  courses: { courses: string[], states: string[], grade: string[] }
  locations: Country[]
  skills: Skill[]
  contractTypes: string[]
  coursesCategories: string[],
  languages: {language: string[], level: string[]}
}

function getCourseFilterQuery (courses: FiltersTalentState['courses']): string {
  let query = ''
  const pairsCourses = []
  const pairsStates = []
  const grades = []
  for (const key in courses.courses) {
    if (Object.prototype.hasOwnProperty.call(courses.courses, key)) pairsCourses.push(encodeURIComponent(courses.courses[key]))
  }
  if (pairsCourses.length > 0) query += 'courses=' + pairsCourses.join(encodeURIComponent('|')) + '&'

  for (const key in courses.states) {
    if (Object.prototype.hasOwnProperty.call(courses.states, key)) pairsStates.push(encodeURIComponent(courses.states[key]))
  }
  if (pairsStates.length > 0) query += 'states=' + pairsStates.join('|') + '&'

  for (const grade of courses.grade.flat()) {
    grades.push(grade)
  }
  if (grades.length > 0) query += 'grade=' + grades.join('|') + '&'

  return query
}
function getLanguagesFilterQuery (query: string, languages: FiltersTalentState['languages']): string {
  const pairsLanguages = []
  for (const keyLang in languages.language) {
    if (languages.level.length === 0) {
      pairsLanguages.push(encodeURIComponent(languages.language[keyLang]))
    } else {
      for (const keyLevel in languages.level) {
        pairsLanguages.push(encodeURIComponent(languages.language[keyLang] + '-' + languages.level[keyLevel]))
      }
    }
  }
  if (pairsLanguages.length > 0) query += 'languages=' + pairsLanguages.join(encodeURIComponent('|'))
  return query
}

export const useFiltersTalentStore = defineStore({
  id: StoreIds.FiltersTalentStore,
  state: (): FiltersTalentState => ({
    courses: { courses: [], states: [], grade: [] },
    keyFilter: '',
    locations: [],
    skills: [],
    contractTypes: [],
    coursesCategories: [],
    languages: {
      language: [],
      level: []
    }
  }),
  getters: {
    getQueryParam (): string {
      const pairsLocation = []
      const pairsSkills = []
      const pairsCourseCategories = []
      let query: string = getCourseFilterQuery(this.courses)
      for (const key in this.locations) {
        if (Object.prototype.hasOwnProperty.call(this.locations, key)) pairsLocation.push(encodeURIComponent(this.locations[key].name))
      }
      if (pairsLocation.length > 0) query += 'locations=' + pairsLocation.join(encodeURIComponent('|')) + '&'

      for (const key in this.skills) {
        if (Object.prototype.hasOwnProperty.call(this.skills, key)) pairsSkills.push(encodeURIComponent(this.skills[key].name))
      }
      if (pairsSkills.length > 0) query += 'skills=' + pairsSkills.join(encodeURIComponent('|')) + '&'

      for (const key in this.coursesCategories) {
        if (Object.prototype.hasOwnProperty.call(this.coursesCategories, key)) pairsCourseCategories.push(encodeURIComponent(this.coursesCategories[key]))
      }
      if (pairsCourseCategories.length > 0) query += 'categories=' + pairsCourseCategories.join(encodeURIComponent('|')) + '&'

      if (this.keyFilter !== '') query += 'keyFilter=' + this.keyFilter + '&'
      return getLanguagesFilterQuery(query, this.languages)
    },
    getTotalCourses (): number {
      return this.courses.courses.length
    },
    getTotalLocations (): number {
      return this.locations.length
    },
    getTotalSkills (): number {
      return this.skills.length
    },
    getTotalLanguages (): number {
      return this.languages.language.length
    },
    getTotalContractTypes (): number {
      return this.contractTypes.length
    },
    getTotalCourseCategories (): number {
      return this.coursesCategories.length
    }
  },
  actions: {
    updateKeyFilter (value: string) {
      this.keyFilter = value
    },
    updateCourses (value: { courses: string[], states: string[], grade: string[] }) {
      this.courses = value
    },
    updateLocations (value: Country[]) {
      this.locations = value
    },
    updateSkills (value: Skill[]) {
      this.skills = value
    },
    updateLanguages (value: {language: string[], level: string[]}) {
      console.log(value)
      this.languages = value
    },
    updateContractTypes (value: string[]) {
      this.contractTypes = value
    },
    updateCourseCategories (value: string[]) {
      this.coursesCategories = value
    }
  }
})
