import { Faq } from '@/models/faq'
import { Api } from './api'

async function findFaqs (): Promise<Faq[]> {
  return Api.get<Faq[]>('/faqs')
}

export {
  findFaqs
}
