
import { defineComponent, ref, reactive } from 'vue'
import useVuelidate from '@vuelidate/core'
import { required, email } from '@vuelidate/validators'
import usersApi from '@/api/users.api'

export default defineComponent({
  name: 'PasswordRecovery',
  setup () {
    const state = reactive({
      email: ''
    })

    const noInputSubmit = ref(false)
    const recoverySuccess = ref(false)

    const rules = {
      email: { required, email }
    }

    const v$ = useVuelidate(rules, state)

    const submit = async () => {
      v$.value.$touch()
      const isFormCorrect = await v$.value.$validate()

      if (!isFormCorrect) return

      try {
        await usersApi.sendMailIfExist(state.email)
        recoverySuccess.value = true

        v$.value.$reset()
        state.email = ''
      } catch (error) {
        console.log(error)
      }
    }

    const closeSuccessMsg = () => {
      recoverySuccess.value = false
    }

    const resetValidate = () => {
      v$.value.email.$reset()
    }

    return {
      state,
      v$,
      submit,
      noInputSubmit,
      recoverySuccess,
      closeSuccessMsg,
      resetValidate
    }
  }
})
