import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, withKeys as _withKeys, createTextVNode as _createTextVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-62891112"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "p-col-12 p-xl-5 register-container" }
const _hoisted_2 = {
  style: {"width":"100%","text-align":"center"},
  class: "form-title"
}
const _hoisted_3 = { class: "form-container p-d-flex p-flex-wrap p-jc-center" }
const _hoisted_4 = { class: "p-d-flex p-flex-column p-jc-evenly p-px-3 social_container p-mb-6" }
const _hoisted_5 = ["src"]
const _hoisted_6 = ["src"]
const _hoisted_7 = {
  key: 0,
  class: "form-error",
  style: {"text-align":"center"}
}
const _hoisted_8 = { class: "person-type p-px-2 p-px-lg-5" }
const _hoisted_9 = { class: "userType-heading" }
const _hoisted_10 = { class: "radio-buttons" }
const _hoisted_11 = {
  for: "empresa",
  class: "userType-option"
}
const _hoisted_12 = {
  for: "freelance",
  class: "userType-option"
}
const _hoisted_13 = {
  key: 0,
  class: "form-error"
}
const _hoisted_14 = {
  key: 0,
  class: "p-field p-d-flex p-flex-column p-px-2 p-px-lg-5"
}
const _hoisted_15 = {
  key: 0,
  for: "name",
  class: "p-text-left"
}
const _hoisted_16 = {
  key: 1,
  for: "name",
  class: "p-text-left"
}
const _hoisted_17 = {
  key: 2,
  class: "form-error"
}
const _hoisted_18 = { class: "p-field p-d-flex p-flex-column p-px-2 p-px-lg-5" }
const _hoisted_19 = {
  for: "userName",
  class: "p-text-left"
}
const _hoisted_20 = { class: "p-text-left" }
const _hoisted_21 = {
  key: 0,
  class: "form-error"
}
const _hoisted_22 = { class: "p-field p-d-flex p-flex-column p-px-2 p-px-lg-5" }
const _hoisted_23 = {
  for: "userName",
  class: "p-text-left"
}
const _hoisted_24 = { class: "p-text-left" }
const _hoisted_25 = {
  key: 0,
  class: "form-error"
}
const _hoisted_26 = {
  key: 1,
  class: "form-error"
}
const _hoisted_27 = {
  key: 2,
  class: "form-error"
}
const _hoisted_28 = { class: "p-field p-d-flex p-flex-column p-px-2 p-px-lg-5" }
const _hoisted_29 = {
  for: "password",
  class: "p-text-left"
}
const _hoisted_30 = { class: "p-text-left" }
const _hoisted_31 = { class: "p-input-icon-right w-100" }
const _hoisted_32 = {
  key: 0,
  class: "form-error"
}
const _hoisted_33 = {
  key: 1,
  class: "form-error"
}
const _hoisted_34 = { class: "p-field" }
const _hoisted_35 = { class: "p-field-checkbox p-d-flex p-pl-lg-3 terms" }
const _hoisted_36 = { for: "binary" }
const _hoisted_37 = {
  key: 0,
  class: "p-field-checkbox form-error p-pl-lg-3"
}
const _hoisted_38 = {
  key: 1,
  class: "register-success"
}
const _hoisted_39 = { class: "register_success__icon-info" }
const _hoisted_40 = ["src"]
const _hoisted_41 = { class: "register_success__text" }
const _hoisted_42 = ["src"]
const _hoisted_43 = { class: "p-d-flex w-100 p-jc-center" }
const _hoisted_44 = { class: "p-d-flex w-100 p-ai-center p-jc-between p-py-5 p-px-3" }
const _hoisted_45 = { class: "login-redirect-txt" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Button = _resolveComponent("Button")!
  const _component_RadioButton = _resolveComponent("RadioButton")!
  const _component_InputText = _resolveComponent("InputText")!
  const _component_Checkbox = _resolveComponent("Checkbox")!
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("h2", _hoisted_2, _toDisplayString(_ctx.$t('registerForm.title')), 1),
    _createElementVNode("section", _hoisted_3, [
      _createElementVNode("div", _hoisted_4, [
        _createVNode(_component_Button, {
          label: "Continuar con Google",
          icon: "pi pi-google",
          iconPos: "left",
          class: "p-button-text p-button-text button-social p-my-2",
          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.accessWith('google-oauth2')))
        }, {
          default: _withCtx(() => [
            _createElementVNode("img", {
              src: require('@/assets/icons/google-color.svg'),
              alt: "facebook-icon"
            }, null, 8, _hoisted_5),
            _createElementVNode("span", null, _toDisplayString(_ctx.$t('registerForm.loginGoogle')), 1)
          ]),
          _: 1
        }),
        _createVNode(_component_Button, {
          label: "Continuar con Facebook",
          class: "p-button-text p-button-text button-social",
          onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.accessWith('facebook')))
        }, {
          default: _withCtx(() => [
            _createElementVNode("img", {
              src: require('@/assets/icons/facebook-color.svg'),
              alt: "facebook-icon"
            }, null, 8, _hoisted_6),
            _createElementVNode("span", null, _toDisplayString(_ctx.$t('registerForm.loginFacebook')), 1)
          ]),
          _: 1
        }),
        (_ctx.uncheckedTerms)
          ? (_openBlock(), _createElementBlock("div", _hoisted_7, _toDisplayString(_ctx.$t('registerForm.alerts.uncheckedTerms')), 1))
          : _createCommentVNode("", true)
      ]),
      _createElementVNode("div", _hoisted_8, [
        _createElementVNode("span", _hoisted_9, _toDisplayString(_ctx.$t('registerForm.labels.userTypeHeading')), 1),
        _createElementVNode("div", _hoisted_10, [
          _createElementVNode("div", {
            class: _normalizeClass({selectedOption: _ctx.state.userType == 'empresa', unselectedOption: _ctx.state.userType !== 'empresa'})
          }, [
            _createVNode(_component_RadioButton, {
              onClick: _ctx.closeSuccessMsg,
              id: "empresa",
              name: "city",
              value: "empresa",
              modelValue: _ctx.v$.userType.$model,
              "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.v$.userType.$model) = $event))
            }, null, 8, ["onClick", "modelValue"]),
            _createElementVNode("label", _hoisted_11, _toDisplayString(_ctx.$t('registerForm.labels.business')), 1)
          ], 2),
          _createElementVNode("div", {
            class: _normalizeClass({selectedOption: _ctx.state.userType == 'freelance', unselectedOption: _ctx.state.userType !== 'freelance'})
          }, [
            _createVNode(_component_RadioButton, {
              onClick: _ctx.closeSuccessMsg,
              name: "city",
              value: "freelance",
              id: "freelance",
              modelValue: _ctx.v$.userType.$model,
              "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.v$.userType.$model) = $event))
            }, null, 8, ["onClick", "modelValue"]),
            _createElementVNode("label", _hoisted_12, _toDisplayString(_ctx.$t('registerForm.labels.freelance')), 1)
          ], 2),
          (_ctx.v$.userType.$error)
            ? (_openBlock(), _createElementBlock("div", _hoisted_13, _toDisplayString(_ctx.$t('registerForm.alerts.unselectedType')), 1))
            : _createCommentVNode("", true)
        ])
      ]),
      (_ctx.v$.userType.$model)
        ? (_openBlock(), _createElementBlock("div", _hoisted_14, [
            (_ctx.v$.userType.$model=='freelance')
              ? (_openBlock(), _createElementBlock("label", _hoisted_15, _toDisplayString(_ctx.$t('registerForm.labels.fullName')), 1))
              : _createCommentVNode("", true),
            (_ctx.v$.userType.$model=='empresa')
              ? (_openBlock(), _createElementBlock("label", _hoisted_16, _toDisplayString(_ctx.$t('registerForm.labels.businessName')), 1))
              : _createCommentVNode("", true),
            _createElementVNode("div", null, [
              _createVNode(_component_InputText, {
                onFocus: _ctx.closeSuccessMsg,
                class: _normalizeClass(["p-inputtext-lg dark-input", { 'input-error': _ctx.v$.name.$error }]),
                id: "name",
                type: "text",
                modelValue: _ctx.v$.name.$model,
                "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.v$.name.$model) = $event)),
                placeholder: _ctx.v$.userType.$model=='freelance'? _ctx.$t('registerForm.placeholders.fullName') : _ctx.$t('registerForm.placeholders.businessName')
              }, null, 8, ["onFocus", "class", "modelValue", "placeholder"])
            ]),
            (_ctx.v$.name.$error)
              ? (_openBlock(), _createElementBlock("div", _hoisted_17, _toDisplayString(_ctx.$t('registerForm.alerts.emptyField')), 1))
              : _createCommentVNode("", true)
          ]))
        : _createCommentVNode("", true),
      _createElementVNode("div", _hoisted_18, [
        _createElementVNode("label", _hoisted_19, _toDisplayString(_ctx.$t('registerForm.labels.country')), 1),
        _createElementVNode("div", _hoisted_20, [
          _createVNode(_component_InputText, {
            onFocus: _ctx.closeSuccessMsg,
            class: _normalizeClass(["p-inputtext-lg dark-input", { 'input-error': _ctx.v$.country.$error }]),
            id: "country",
            type: "text",
            modelValue: _ctx.v$.country.$model,
            "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.v$.country.$model) = $event)),
            placeholder: _ctx.$t('registerForm.placeholders.country')
          }, null, 8, ["onFocus", "class", "modelValue", "placeholder"])
        ]),
        (_ctx.v$.country.$error)
          ? (_openBlock(), _createElementBlock("div", _hoisted_21, _toDisplayString(_ctx.$t('registerForm.alerts.emptyField')), 1))
          : _createCommentVNode("", true)
      ]),
      _createElementVNode("div", _hoisted_22, [
        _createElementVNode("label", _hoisted_23, _toDisplayString(_ctx.$t('registerForm.labels.email')), 1),
        _createElementVNode("div", _hoisted_24, [
          _createVNode(_component_InputText, {
            onFocus: _ctx.closeSuccessMsg,
            modelValue: _ctx.v$.email.$model,
            "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.v$.email.$model) = $event)),
            onInput: _ctx.resetValidate,
            class: _normalizeClass(["p-inputtext-lg dark-input", { 'input-error': _ctx.v$.email.$error || _ctx.state.alreadyInUse }]),
            id: "email",
            type: "text",
            placeholder: _ctx.$t('registerForm.placeholders.email')
          }, null, 8, ["onFocus", "modelValue", "onInput", "class", "placeholder"])
        ]),
        (_ctx.v$.email.$errors[0]?.$validator == 'email')
          ? (_openBlock(), _createElementBlock("div", _hoisted_25, _toDisplayString(_ctx.$t('registerForm.alerts.invalidEmail')), 1))
          : _createCommentVNode("", true),
        (_ctx.v$.email.$errors[0]?.$validator == 'required')
          ? (_openBlock(), _createElementBlock("div", _hoisted_26, _toDisplayString(_ctx.$t('registerForm.alerts.emptyField')), 1))
          : _createCommentVNode("", true),
        (_ctx.state.alreadyInUse)
          ? (_openBlock(), _createElementBlock("div", _hoisted_27, _toDisplayString(_ctx.$t('registerForm.alerts.emailAlreadyInUse')), 1))
          : _createCommentVNode("", true)
      ]),
      _createElementVNode("div", _hoisted_28, [
        _createElementVNode("label", _hoisted_29, _toDisplayString(_ctx.$t('registerForm.labels.password')), 1),
        _createElementVNode("div", _hoisted_30, [
          _createElementVNode("span", _hoisted_31, [
            _createElementVNode("i", {
              onMouseleave: _cache[7] || (_cache[7] = ($event: any) => (_ctx.state.showPassword=false)),
              onMouseover: _cache[8] || (_cache[8] = ($event: any) => (_ctx.state.showPassword=true)),
              class: _normalizeClass(["pi pi-eye-slash", _ctx.state.showPassword ? 'pi-eye-slash' : 'pi-eye'])
            }, null, 34),
            _createVNode(_component_InputText, {
              onFocus: _ctx.closeSuccessMsg,
              class: _normalizeClass(["p-inputtext-lg dark-input", { 'input-error': _ctx.v$.password.$error || _ctx.state.weakPassword}]),
              id: "password",
              type: _ctx.state.showPassword ? 'text' : 'password',
              placeholder: _ctx.$t('registerForm.placeholders.password'),
              modelValue: _ctx.v$.password.$model,
              "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.v$.password.$model) = $event)),
              onKeyup: _withKeys(_ctx.submit, ["enter"])
            }, null, 8, ["onFocus", "class", "type", "placeholder", "modelValue", "onKeyup"])
          ]),
          (_ctx.state.weakPassword)
            ? (_openBlock(), _createElementBlock("div", _hoisted_32, _toDisplayString(_ctx.$t('registerForm.alerts.invalidPassword')), 1))
            : _createCommentVNode("", true),
          (_ctx.v$.password.$error)
            ? (_openBlock(), _createElementBlock("div", _hoisted_33, _toDisplayString(_ctx.$t('registerForm.alerts.emptyField')), 1))
            : _createCommentVNode("", true)
        ])
      ]),
      _createElementVNode("div", _hoisted_34, [
        _createElementVNode("div", _hoisted_35, [
          _createVNode(_component_Checkbox, {
            onClick: _ctx.closeSuccessMsg,
            id: "binary",
            class: "dark-checkbox",
            modelValue: _ctx.v$.termsConditions.$model,
            "onUpdate:modelValue": _cache[10] || (_cache[10] = ($event: any) => ((_ctx.v$.termsConditions.$model) = $event)),
            binary: true
          }, null, 8, ["onClick", "modelValue"]),
          _createElementVNode("label", _hoisted_36, [
            _createTextVNode(_toDisplayString(_ctx.$t('registerForm.terms.unlinked')), 1),
            _createVNode(_component_router_link, {
              to: "/legales-coder-talento",
              target: "_blank",
              class: "secondary-text"
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.$t('registerForm.terms.linked')), 1)
              ]),
              _: 1
            })
          ])
        ]),
        (_ctx.v$.termsConditions.$error)
          ? (_openBlock(), _createElementBlock("div", _hoisted_37, _toDisplayString(_ctx.$t('registerForm.alerts.uncheckedTerms')), 1))
          : _createCommentVNode("", true)
      ]),
      (_ctx.registerSuccess)
        ? (_openBlock(), _createElementBlock("div", _hoisted_38, [
            _createElementVNode("div", _hoisted_39, [
              _createElementVNode("img", {
                src: require('@/assets/icons/info.svg'),
                alt: "info-icon"
              }, null, 8, _hoisted_40)
            ]),
            _createElementVNode("div", _hoisted_41, _toDisplayString(_ctx.$t('registerForm.successMsg.registration', { lastEmail: _ctx.lastEmail })), 1),
            _createElementVNode("div", {
              onClick: _cache[11] || (_cache[11] = 
//@ts-ignore
(...args) => (_ctx.closeSuccessMsg && _ctx.closeSuccessMsg(...args))),
              class: "register_success__icon-close"
            }, [
              _createElementVNode("img", {
                src: require('@/assets/icons/close.svg'),
                alt: "close-icon"
              }, null, 8, _hoisted_42)
            ])
          ]))
        : _createCommentVNode("", true),
      _createElementVNode("div", _hoisted_43, [
        _createVNode(_component_Button, {
          label: _ctx.isLoading ? '' : _ctx.$t('registerForm.submit'),
          class: "p-button-secondary",
          loading: _ctx.isLoading,
          onClick: _ctx.submit
        }, null, 8, ["label", "loading", "onClick"])
      ]),
      _createElementVNode("div", _hoisted_44, [
        _createElementVNode("span", _hoisted_45, _toDisplayString(_ctx.$t('registerForm.redirectToLogin.unlinked')), 1),
        _createVNode(_component_router_link, {
          to: "/login",
          class: "secondary-text p-pr-3 login-redirect-txt"
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.$t('registerForm.redirectToLogin.linked')), 1)
          ]),
          _: 1
        })
      ])
    ])
  ]))
}