<template>
  <div class="p-d-flex p-flex-wrap filter-content">
    <div class="grade-filter p-col-12 p-lg-12">
      <h3 class="filter-title">{{ title }}</h3>
      <div class="p-d-sm-flex">
        <span class="p-input-icon-left">
          <i class="pi pi-search" />
          <InputText class="items-search" type="text" :modelValue="search" @input="$emit('update:search', $event.target.value)" @keyup="($emit('searchItems', search))" :placeholder="placeholder" />
        </span>
      </div>
      <div class="selected-items">
        <div class="items-selected p-mb-3 p-my-1">
          <label class="item-detail" :key="item.name" v-for="item in selectedItems" >{{ item.name }}
            <Button
              icon="pi pi-times"
              class="ch-button"
              @click="($emit('removeItem',item))"
            />
          </label>
        </div>
      </div>
      <div class='p-d-flex filtered-items-container' v-if="search && search.length && itemsList.length">
        <div class="p-col-12 ">
          <div class="filtered-items">
            <div class="items" :key="item.name" v-for="item in itemsList" @click="($emit('selectItem', item))">
              <p class="p-text-center">{{ $t(item.name) }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script src="./InputItemSearch.ts"></script>
<style scoped lang="scss" src="./InputItemSearch.scss"></style>
