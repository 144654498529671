console.log(process.env.AWS_REGION)
const awsConfig = {
  region: process.env.TALENT_AWS_REGION,
  userPoolId: process.env.TALENT_AWS_USER_POOLS_ID,
  userPoolWebClientId: process.env.TALENT_AWS_USER_POOLS_WEB_CLIENT_ID,
  mandatorySignIn: true,
  redirectSignIn: 'http://localhost:3000/home',
  redirectSignOut: 'http://localhost:3000/',
  responseType: 'token' // or 'token', note that REFRESH token will only be generated when the responseType is code
}
export default awsConfig
