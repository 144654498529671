import { findFaqs } from '@/api/faqs.api'
import { ref, onMounted } from 'vue'
import { Faq } from '@/models/faq'
import { useToast } from 'primevue/usetoast'
import { ToastLife, ToastSeverities, ToastSummaries } from '@/models/primevue/toast'
import { useI18n } from 'vue-i18n'

export default function usefindFaqs () {
  const faqs = ref<Faq[]>([])
  const total = ref<number>(0)
  const toast = useToast()
  const loadingFaqs = ref<boolean>(true)
  const { t } = useI18n({ useScope: 'global' })

  const getFaqs = async () => {
    try {
      loadingFaqs.value = true
      const data = await findFaqs()
      faqs.value = data
    } catch (error) {
      toast.add({
        summary: ToastSummaries.Error,
        detail: t('global.alerts.faqsLoad'),
        severity: ToastSeverities.Error,
        life: ToastLife.Default
      })
      console.error(error)
    } finally {
      loadingFaqs.value = false
    }
  }

  onMounted(() => {
    getFaqs()
  })

  return {
    faqs,
    getFaqs,
    loadingFaqs,
    total
  }
}
