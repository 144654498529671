
import { defineComponent } from 'vue'
import Header from '@/components/commons/Header.vue'

export default defineComponent({
  name: 'Layout',
  components: {
    Header
  }
})
