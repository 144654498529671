import { defineComponent, ref, watch, defineExpose } from 'vue'
import Checkbox from 'primevue/checkbox'
import { useFiltersTalentStore } from '@/store/modules/commons/filters-talent-store'

const contractsType = [
  'contracts.type.fulltime',
  'contracts.type.partime',
  'contracts.type.contractor',
  'contracts.type.practice'
]

export default defineComponent({
  name: 'FilterContractType',
  components: {
    Checkbox
  },
  setup () {
    const assignedFiltersTalentStore = useFiltersTalentStore()
    const selectedContractType = ref<string[]>([])

    const deleteItem = (item: string) => {
      const index = selectedContractType.value.indexOf(item)
      selectedContractType.value.splice(index, 1)
    }

    const deleteFilter = () => {
      assignedFiltersTalentStore.updateContractTypes([])
    }

    const updateContractType = () => {
      assignedFiltersTalentStore.updateContractTypes(selectedContractType.value)
    }

    watch(selectedContractType, updateContractType)

    function clearFilter () {
      selectedContractType.value = []
    }

    defineExpose({
      clearFilter
    })

    return {
      contractsType,
      selectedContractType,
      deleteItem,
      deleteFilter,
      clearFilter
    }
  }
})
