<template>
    <div class="terms-main">
        <div class="terms-container p-col-9 p-md-8">
            <div class="terms-head">
                <span class="head-top">{{ $t('terms&conditions.headTop') }}</span>
                <span class="head-bottom">{{ $t('terms&conditions.headBottom') }}</span>
            </div>
            <p>{{ $t('terms&conditions.warning.one') }}</p>

            <p>{{ $t('terms&conditions.warning.two') }}</p>

            <p>{{ $t('terms&conditions.warning.three') }}</p>

            <p>{{ $t('terms&conditions.warning.conector') }}</p>

            <p><strong>{{ $t('terms&conditions.studentsConditions.target') }}</strong></p>

            <p>{{ $t('terms&conditions.studentsConditions.first', {at: '@'}) }}</p>

            <p>{{ $t('terms&conditions.studentsConditions.second') }}</p>

            <p>{{ $t('terms&conditions.studentsConditions.third') }}</p>

            <p>{{ $t('terms&conditions.studentsConditions.fourth') }}</p>

            <p>{{ $t('terms&conditions.studentsConditions.fifth') }}</p>

            <p>{{ $t('terms&conditions.studentsConditions.sixth') }}</p>

            <p>{{ $t('terms&conditions.studentsConditions.seventh') }}</p>

            <p>{{ $t('terms&conditions.studentsConditions.eighth') }}</p>

            <p><strong>{{ $t('terms&conditions.businessConditions.target') }}</strong></p>

            <p>{{ $t('terms&conditions.businessConditions.first') }}</p>

            <p>{{ $t('terms&conditions.businessConditions.second') }}</p>

            <p>{{ $t('terms&conditions.businessConditions.third') }}</p>

            <p>{{ $t('terms&conditions.businessConditions.fourth') }}</p>

            <p>{{ $t('terms&conditions.businessConditions.fifth') }}</p>

            <p>{{ $t('terms&conditions.businessConditions.sixth') }}</p>
        </div>
        <Footer></Footer>
    </div>
</template>
<script>
import { defineComponent } from 'vue'
import Footer from '@/components/commons/Footer.vue'

export default defineComponent({
  name: 'Terms',
  components: {
    Footer
  }
})
</script>
<style lang="scss" scoped>
    .terms-main {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        background-color: #1F2023;
    }
    .terms-container {
        display: flex;
        flex-direction: column;
        margin-top: 75px;
        margin-bottom: 196px;

        * {
            font-family: $font-untitled;
        }
    }
    .terms-head {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-bottom: 96px;
    }
    .head-top {
        font-weight: normal;
        font-size: 24px;
        line-height: 30px;
        letter-spacing: 20px;
        text-transform: uppercase;
    }
    .head-bottom {
        font-family: $font;
        font-size: 56px;
        line-height: 73px;
        font-weight: bold;
    }
    p {
        font-size: 16px;
        line-height: 19px;
    }
    p strong {
        text-decoration: underline;
    }

    @include breakpoint-sm-max {
        .head-top {
            font-size: 20px;
            letter-spacing: 2px;
        }
    }
</style>
