import { RouteRecordRaw } from 'vue-router'
import Talents from '@/views/talent/talents/Talents.vue'
import TalentProfile from '@/views/talent/talent-profile/TalentProfile.vue'
import { RouteNames } from '@/router/route-names'

const TalentRoutes: Array<RouteRecordRaw> = [
  {
    path: '',
    name: RouteNames.TALENT_GENERAL,
    component: Talents
  },
  {
    path: '/talent/:talentId',
    name: RouteNames.TALENT_PROFILE,
    component: TalentProfile,
    redirect: to => ({
      name: RouteNames.TALENT_PROFILE_GENERAL,
      params: {
        talentId: to.params.talentId
      }
    }),
    children: [
      {
        path: 'profile',
        name: RouteNames.TALENT_PROFILE_GENERAL,
        component: TalentProfile
      }
    ]
  },
  {
    path: '',
    redirect: { name: 'Talents' }
  }
]

export default TalentRoutes
