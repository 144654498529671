
<template>
  <InputItemSearch
    :placeholder="$t('talents.filters.skills.search')"
    :title="$t('talents.filters.skills')"
    :search="search"
    @update:search="search = $event"
    @searchItems="searchSkills"
    :selectedItems="selectedSkills"
    :itemsList="techSkills"
    @selectItem="selectSkill"
    @removeItem="removeSkill"
  />
</template>

<script src="./FilterSkill.ts" ></script>
<style scoped lang="scss" src="./FilterSkill.scss"></style>
