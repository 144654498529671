
<template>

  <div class="p-d-flex p-flex-wrap filter-content">
    <div class="grade-filter p-col-12 p-lg-12">
      <MultiSelectCheckbox
        class="language-container"
        :header="$t('talents.filters.language.select')"
        :title="$t('talents.filters.language')"
        @updateItems="updateLanguages"
        :items="languages"
        ref="opLanguageSelector"
      />
      <div class="p-grid p-d-flex p-flex-wrap p-mt-2">
        <div class="p-field-checkbox p-m-0 p-col-6 p-md-6 p-sm-12" v-for="level in levels" :key="level">
            <Checkbox
              class="ch-checkbox"
              v-model="selectedLevels"
              :id="level"
              :name="level"
              :value="$t(level)"
              :disabled="selectedLanguages.length === 0"
            />
            <label class="text" :for="level">{{ $t(level) }}</label>
          </div>
      </div>
    </div>
  </div>
</template>

<script src="./FilterLanguage.ts" ></script>
<style scoped lang="scss" src="./FilterLanguage.scss"></style>
