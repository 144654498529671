import { Talent, CoderCourse, FinalAssignment } from '@/models/talents'

export default function FinalAssignmentFilter (talent: Talent) {
  const now = new Date()
  const dayMilisec = 1000 * 60 * 60 * 24
  const expirationTimeInDays = 730

  const filteredResult: Array<FinalAssignment> = []
  let lastFinalAssignment: FinalAssignment = { name: '', link: '' }

  const filteredAssignments = () => {
    (talent?.coderCourses || []).forEach((course: CoderCourse) => {
      if (course.endDate) {
        const dayDiff = Math.floor((now.getTime() - new Date(course.endDate).getTime()) / dayMilisec)

        if (course.attachedDocuments && dayDiff < expirationTimeInDays) {
          const finalAssignment: FinalAssignment = { name: course.name, link: course.attachedDocuments }
          filteredResult.push(finalAssignment)
        }
      }
    })

    return filteredResult
  }

  const getLastAssignment = () => {
    (talent?.coderCourses || []).forEach((course: CoderCourse) => {
      if (course.endDate) {
        const dayDiff = Math.floor((now.getTime() - new Date(course.endDate).getTime()) / dayMilisec)

        if (course.attachedDocuments && dayDiff < expirationTimeInDays) {
          lastFinalAssignment = { name: course.name, link: course.attachedDocuments }
        }
      }
    })

    return lastFinalAssignment
  }

  return {
    filteredAssignments,
    getLastAssignment
  }
}
