import { getSkills } from '@/api/talents.api'
import { ref, onMounted } from 'vue'
import { useToast } from 'primevue/usetoast'
import { ToastLife, ToastSeverities, ToastSummaries } from '@/models/primevue/toast'
import { Skill } from '@/models/talents'
import { useI18n } from 'vue-i18n'

export default function useGetSkills () {
  const skills = ref<Skill[]>([])
  const toast = useToast()
  const loadingSkills = ref<boolean>(true)
  const { t } = useI18n({ useScope: 'global' })

  const getSkillsHook = async () => {
    try {
      loadingSkills.value = true
      skills.value = await getSkills()
    } catch (error) {
      toast.add({
        summary: ToastSummaries.Error,
        detail: t('talents.alerts.loadError'),
        severity: ToastSeverities.Error,
        life: ToastLife.Default
      })
      console.error(error)
    } finally {
      loadingSkills.value = false
    }
  }

  onMounted(getSkillsHook)

  return {
    skills,
    getSkillsHook,
    loadingSkills
  }
}
