
import { defineComponent } from 'vue'
import { useRoute } from 'vue-router'
import Expired from '@/components/auth/Expired.vue'
import Confirmated from '@/components/auth/Confirmated.vue'
import LoadingScreen from '@/components/commons/LoadingScreen.vue'
import useFindEmailVerifications from '@/hooks/useFindEmailVerifications'

export default defineComponent({
  components: {
    Expired,
    Confirmated,
    LoadingScreen
  },
  setup () {
    const route = useRoute()
    const token = route.query.token as string

    const { loadingVerifications, response } = useFindEmailVerifications(token)

    return {
      loadingVerifications,
      response
    }
  }
})
