
import { defineComponent, ref, onMounted } from 'vue'

import { useToast } from 'primevue/usetoast'
import { ToastLife, ToastSeverities, ToastSummaries } from '@/models/primevue/toast'
import SkillsList from '@/components/talent/talent-profile/education/skills/SkillsList.vue'
import EducationTable from '@/components/talent/talent-profile/education/carreer/EducationTable.vue'
import { Talent, CoderCourse } from '@/models/talents'
import { useI18n } from 'vue-i18n'

export default defineComponent({
  name: 'EducationAndSkills',
  components: {
    SkillsList,
    EducationTable
  },
  props: {
    talent: {
      required: true,
      type: Talent
    }
  },
  setup (props) {
    const toast = useToast()
    const url = ref('')
    const { t } = useI18n({ useScope: 'global' })

    const copySuccess = () => {
      toast.add({ severity: ToastSeverities.Success, summary: ToastSummaries.Copied, detail: t('talents.fullProfile.toast.copied'), life: ToastLife.Default })
    }

    const copyFailure = () => {
      toast.add({ severity: ToastSeverities.Error, summary: ToastSummaries.Error, detail: t('talents.fullProfile.toast.copyError'), life: ToastLife.Default })
    }

    const copyToClipboard = () => {
      navigator.clipboard.writeText(`${url.value}`).then(copySuccess, copyFailure)
    }

    const getURL = () => {
      url.value = window.location.href
    }

    const sortByCompletion = (a: CoderCourse, b: CoderCourse) => {
      if (a.status === 'Graduado') return -1
      if (a.status === 'En curso') return 1

      return 0
    }

    const courses = props.talent.coderCourses?.map(x => x).sort(sortByCompletion)

    onMounted(getURL)

    return { courses, copyToClipboard }
  }
})
