import { defineComponent, PropType } from 'vue'
import { RouteNames } from '@/router/route-names'
import { Skill, LanguageLevel, Country } from '@/models/talents'
import Tooltip from 'primevue/tooltip'

export default defineComponent({
  name: 'DescriptionTalent',
  components: {
  },
  directives: {
    tooltip: Tooltip
  },
  props: {
    talentId: {
      talentId: true,
      type: String
    },
    presentation: {
      presentation: true,
      type: String
    },
    skills: {
      required: false,
      type: Object as PropType<Skill[]>
    },
    languages: {
      required: false,
      type: Object as PropType<LanguageLevel[]>
    },
    country: {
      required: false,
      type: Object as PropType<Country>
    },
    city: {
      required: false,
      type: String
    },
    curriculum: {
      required: false,
      type: String
    },
    name: {
      required: false,
      type: String
    },
    finalAssignment: {
      required: false
    }
  },
  setup () {
    return {
      RouteNames
    }
  }
})
