import { Auth0EmailVerifyResponse } from '@/models/emailVerifiy'
import axios from 'axios'
import { Auth } from 'aws-amplify'
import { Api } from './api'

interface Auth0LoginResponse {
  access_token: string,
  id_token: string,
  scope: string,
  expires_in: number,
  token_type: string,
  error?: string,
  error_description?: string,
  error_uri?: string
}

interface Auth0SignUpResponse {
  _id: string;
  email_verified: boolean;
  email: string;
  username: string;
  given_name: string;
  family_name: string;
  name: string;
  nickname: string;
  picture: string;
}

interface UserInfo {
  sub: string,
  name: string,
  given_name: string,
  family_name: string,
  middle_name: string,
  nickname: string,
  preferred_username: string,
  profile: string,
  picture: string,
  website: string,
  email: string,
  email_verified: boolean,
  gender: string,
  birthdate: string,
  zoneinfo: string,
  locale: string,
  phone_number: string,
  phone_number_verified: boolean,
  address: {
    country: string
  },
  updated_at: string
}

const BASE_URL = 'test'

async function login (username: string, password: string): Promise<any> {
  try {
    const response = await Auth.signIn(username, password)
    const data = { username, password }
    /*
    const { status, user_retrieved } = await Api.post<any>('users/login', data)
    if (status !== 'success') { throw Error('invalid user') }
    const { user_id, name, email } = user_retrieved */
    if (response.challengeName && response.challengeName === 'NEW_PASSWORD_REQUIRED') {
      return { changePassword: true, tempUserData: { username, password } }
    } else if (response.signInUserSession && response.signInUserSession.idToken) {
      const access_token = response.signInUserSession.idToken.jwtToken
      axios.defaults.headers.common.IdToken = access_token
      return { id: 'SQWJDWEFJERGG343F', access_token, name: 'test', email: username, avatar: '' }
    }
    throw Error('invalid user')
  } catch (error) {
    console.log(error)
    throw Error('invalid user')
  }
}

async function logout (): Promise<void> {
  delete axios.defaults.headers.common.Authorization
}

async function changePassword (username: string, oldPassword: string, newPassword: string): Promise<any> {
  try {
    const user = await Auth.signIn(username, oldPassword)
    const response = await Auth.completeNewPassword(user, newPassword)
    return { changePassword: true }
  } catch (error) {
    return { changePassword: false }
  }
}

async function register (email: string, password: string, name: string, country: string, userType: string, avatar = '@/assets/icons/avatar.png'): Promise<any> {
  const data = {
    client_id: process.env.VUE_APP_AUTH0_CLIENTID,
    connection: process.env.VUE_APP_AUTH0_DB,
    email,
    password,
    name,
    avatar,
    user_metadata: { userType, country, application: 'talent' }
  }
  await Auth.signUp(email, password)
  return 'sucess'
}

async function recovery (email: string): Promise<string> {
  const data = {
    client_id: process.env.VUE_APP_AUTH0_CLIENTID,
    connection: process.env.VUE_APP_AUTH0_DB,
    email
  }

  return Api.post<string>(`https://${BASE_URL}/dbconnections/change_password`, data)
}

async function findEmailVerifications (token:string): Promise<Auth0EmailVerifyResponse> {
  return Api.get<Auth0EmailVerifyResponse>(`/email/${token}`)
}

async function sendMailIfExist (email:string): Promise<any> {
  const data = {
    email: email
  }
  return Api.post<any>('/email/password-recovery', data)
}

async function sendNewPassword (password:string, token:string): Promise<any> {
  const data = {
    token: token,
    password: password
  }
  return Api.post<any>('/email/change-password', data)
}

async function saveContactInfo (talentId: string, talentName: string, contactType: string, userId: string): Promise<any> {
  const contactInfo = {
    idUser: userId,
    idTalento: talentId,
    nombre: talentName,
    metodoContacto: contactType
  }

  return Api.post<any>('talent-users/contacts', contactInfo)
}

export default {
  login,
  logout,
  changePassword,
  register,
  recovery,
  findEmailVerifications,
  sendMailIfExist,
  sendNewPassword,
  saveContactInfo
}
