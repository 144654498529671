
import { defineComponent } from 'vue'
import RegisterForm from '@/components/auth/register/RegisterForm.vue'
import { useUserSessionStore } from '@/store/modules/commons/user-session-store'

export default defineComponent({
  components: {
    RegisterForm
  },

  setup () {
    const session = useUserSessionStore()
    return {
      session
    }
  }
})
