<template lang="">
    <section class="skills-section">
        <div>
          <h4>{{ $t('talents.profile.attributes.language') }}</h4>
            <template :key="language.name" v-for="language in languages">
                <Tag class="skill-pill" >{{ $t(language.name) }} - {{ $t(language.level) }}</Tag>
            </template>
        </div>
        <div v-for="category in skillCategories" :key="category">
            <h4>{{ $t('talents.fullProfile.skills.category', { category }) }}</h4>
            <template v-for="skill in skills">
                <Tag :value="$t(skill.name)" :key="skill" class="skill-pill" v-if="skill.type == category" rounded></Tag>
            </template>
        </div>
    </section>
</template>
<script>
import { defineComponent, ref } from 'vue'

export default defineComponent({
  name: 'SkillsList',
  components: {},
  props: {
    skills: {
      required: true
    },
    languages: {
      required: true
    }
  },
  setup (props) {
    const skillCategories = ref(new Set())
    const skills = ref(props.skills)
    const languages = ref(props.languages)

    const getCategories = () => {
      skills.value?.forEach(skill => {
        skillCategories.value.add(skill.type)
      })
    }

    getCategories()

    return { skillCategories }
  }
})
</script>

<style scoped lang="scss" src="./SkillsList.scss"></style>
