import { App } from '@vue/runtime-core'

import Avatar from '@/components/avatar/Avatar' // Use 'primevue/avatar' when fixed
import Button from 'primevue/button'
import Card from 'primevue/card'
import Tag from 'primevue/tag'
import DataView from 'primevue/dataview'
import DataViewLayoutOptions from 'primevue/dataviewlayoutoptions'
import Dropdown from 'primevue/dropdown'
import MultiSelect from 'primevue/multiselect'
import InputText from 'primevue/inputtext'
import Checkbox from 'primevue/checkbox'
import Message from 'primevue/message'
import Panel from 'primevue/panel'
import TabPanel from 'primevue/tabpanel'
import TabView from 'primevue/tabview'
import Toolbar from 'primevue/toolbar'
import Column from 'primevue/column'
import DataTable from 'primevue/datatable'
import ToggleButton from 'primevue/togglebutton'
import { Chart } from 'chart.js'
import Tooltip from 'primevue/tooltip'
import BadgeDirective from 'primevue/badgedirective'
import StyleClass from 'primevue/styleclass'
import AutoComplete from 'primevue/autocomplete'

/**
 * Register componentes used in all the application
 * @param app the application to register components to
 */
export function registerCommonComponents (app: App): void {
  app.component('Avatar', Avatar)
  app.component('Button', Button)
  app.component('Card', Card)
  app.component('Tag', Tag)
  app.component('DataView', DataView)
  app.component('Dropdown', Dropdown)
  app.component('MultiSelect', MultiSelect)
  app.component('DataViewLayoutOptions', DataViewLayoutOptions)
  app.component('InputText', InputText)
  app.component('Checkbox', Checkbox)
  app.component('AutoComplete', AutoComplete)
  app.component('Message', Message)
  app.component('TabPanel', TabPanel)
  app.component('Panel', Panel)
  app.component('TabView', TabView)
  app.component('Toolbar', Toolbar)
  app.component('ToggleButton', ToggleButton)
  app.component('Chart', Chart)
  app.component('DataTable', DataTable)
  app.component('Column', Column)
  app.directive('tooltip', Tooltip)
  app.directive('badge', BadgeDirective)
  app.directive('styleclass', StyleClass)
}
