
import { defineComponent, ref } from 'vue'
import { useToast } from 'primevue/usetoast'
import { ToastLife, ToastSeverities, ToastSummaries } from '@/models/primevue/toast'
import OverlayPanel from 'primevue/overlaypanel'
import { useI18n } from 'vue-i18n'
import { useUserSessionStore } from '@/store/modules/commons/user-session-store'
import usersApi from '@/api/users.api'

export default defineComponent({
  name: 'ButtonContactar',
  components: {
    OverlayPanel
  },
  props: {
    email: {
      required: true,
      type: String
    },
    talentName: {
      required: true,
      type: String
    },
    talentId: {
      required: true,
      type: String
    }
  },
  setup (props) {
    const { t } = useI18n({ useScope: 'global' })
    const op = ref()
    const body = () => t('talents.contactButton.mail.body')
    const subject = () => t('talents.contactButton.mail.subject')
    const toast = useToast()
    const session = useUserSessionStore()
    const { id: userId } = session.getUser
    const toggle = (event: any) => {
      op.value.toggle(event)
    }

    const copySuccess = () => {
      toast.add({ severity: ToastSeverities.Success, summary: ToastSummaries.Copied, detail: t('talents.contactButton.toast.copied'), life: ToastLife.Default })
    }

    const copyFailure = () => {
      toast.add({ severity: ToastSeverities.Error, summary: ToastSummaries.Error, detail: t('talents.contactButton.toast.copyError'), life: ToastLife.Default })
    }

    const copyToClipboard = () => {
      navigator.clipboard.writeText(props.email).then(copySuccess, copyFailure)
    }

    const sendDataLayer = (type: string) => {
      window.dataLayer.push({
        event: 'trackEvent',
        eventCategory: 'codertalent',
        eventAction: 'cta-contactar',
        eventLabel: `${type}`
      })
    }

    const saveContactInformation = (type: string) => {
      usersApi.saveContactInfo(props.talentId, props.talentName, type, userId)
    }

    return {
      toggle,
      op,
      body,
      subject,
      copyToClipboard,
      sendDataLayer,
      saveContactInformation
    }
  }
})
