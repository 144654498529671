import { Api } from './api'
import { ILocale, Locale } from '@/models/locale/locale'
import axios from 'axios'

async function getLocale (lang: string): Promise<Locale> {
  const { data: locale } = await axios.get<ILocale>(`https://academic-api-cdn.coderhouse.com/v1/languages/${lang}/context/talent/translations-1.1.json`)
  return new Locale(locale)
}

async function getCountryCode (ip: string): Promise<string> {
  return Api.post<string>('/talent-users/localization', { ip })
}
export { getLocale, getCountryCode }
