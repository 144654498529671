import { findTalents } from '@/api/talents.api'
import { ref, onMounted } from 'vue'
import { Talent } from '@/models/talents'
import { useToast } from 'primevue/usetoast'
import { ToastLife, ToastSeverities, ToastSummaries } from '@/models/primevue/toast'
import { useI18n } from 'vue-i18n'

export default function useFindTalents () {
  const talents = ref<Talent[]>([])
  const total = ref<number>(0)
  const toast = useToast()
  const loadingTalents = ref<boolean>(true)
  const { t } = useI18n({ useScope: 'global' })

  const getTalents = async (page?:number, limit?:number, queryParams = '') => {
    try {
      loadingTalents.value = true
      const data = await findTalents(page, limit, queryParams)
      talents.value = data.data
      total.value = data.totalPages
    } catch (error) {
      toast.add({
        summary: ToastSummaries.Error,
        detail: t('talents.alerts.loadError'),
        severity: ToastSeverities.Error,
        life: ToastLife.Default
      })
      console.error(error)
    } finally {
      loadingTalents.value = false
    }
  }

  return {
    talents,
    getTalents,
    loadingTalents,
    total
  }
}
