
import { defineComponent, ref, reactive } from 'vue'
import useVuelidate from '@vuelidate/core'
import { required, minLength, helpers } from '@vuelidate/validators'
import usersApi from '@/api/users.api'
import { useToast } from 'primevue/usetoast'
import { ToastLife, ToastSeverities, ToastSummaries } from '@/models/primevue/toast'
import { useI18n } from 'vue-i18n'
import { useUserSessionStore } from '@/store/modules/commons/user-session-store'

export default defineComponent({
  name: 'ChangePassword',
  props: {
    closeModal: {
      type: Function,
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      default: () => {},
      required: false
    }
  },
  setup (props, ctx) {
    const state = reactive({
      password: '',
      newPassword: ''
    })

    const weakPassword = ref(false)
    const showSuccessMsg = ref(false)
    const passwordAreDifferents = ref(false)
    const isLoading = ref(false)
    const toast = useToast()
    const { t } = useI18n({ useScope: 'global' })
    const uppercase = helpers.regex(/[A-Z]/)
    const lowercase = helpers.regex(/[a-z]/)
    const number = helpers.regex(/[0-9]/)
    const specialCharacters = helpers.regex(/[.*^$*?!@#%&/\\,><':;|_~`+={}()-]/)

    const rules = {
      password: { required, minLengthValue: minLength(8), uppercase, lowercase, number, specialCharacters },
      newPassword: { required, minLengthValue: minLength(8), uppercase, lowercase, number, specialCharacters }
    }
    const sessionStore = useUserSessionStore()

    const v$ = useVuelidate(rules, state)
    const inputDetected = () => {
      passwordAreDifferents.value = false
    }
    const submit = async () => {
      v$.value.$touch()

      const isFormCorrect = await v$.value.$validate()

      if (!isFormCorrect) return

      if (state.password !== state.newPassword) {
        passwordAreDifferents.value = true
        return
      }

      try {
        isLoading.value = true
        const tempUserData = sessionStore.getTempUsername
        const result = await usersApi.changePassword(tempUserData.username, tempUserData.password, state.password)

        if (result.changePassword) {
          toast.add({ severity: ToastSeverities.Success, summary: ToastSummaries.Success, detail: t('changePasswordForm.changeSuccess'), life: ToastLife.Default })
          props.closeModal()
        }
      } catch (error) {
        console.log(error)
        weakPassword.value = true
      }
    }

    const closeSuccessMsg = () => {
      showSuccessMsg.value = false
    }

    return {
      state,
      v$,
      submit,
      showSuccessMsg,
      passwordAreDifferents,
      weakPassword,
      inputDetected,
      isLoading,
      closeSuccessMsg
    }
  }
})
