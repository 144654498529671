
import { defineComponent, ref } from 'vue'
import CardTalent from '@/components/talent/card/CardTalent/CardTalent.vue'
import FilterTalent from '@/components/talent/card/FilterTalent/FilterTalent.vue'
import PersonalInfo from '@/components/talent/talent-profile/personal-info/PersonalInfo.vue'
import EducationAndSkills from '@/components/talent/talent-profile/education/EducationAndSkills.vue'
import useFindTalents from '@/hooks/useFindTalents'
import Paginator from 'primevue/paginator'
import LoadingScreen from '@/components/commons/LoadingScreen.vue'
import { useFiltersTalentStore } from '@/store/modules/commons/filters-talent-store'
import { Talent } from '@/models/talents'

export default defineComponent({
  name: 'Talents',
  components: {
    CardTalent,
    Paginator,
    LoadingScreen,
    FilterTalent,
    PersonalInfo,
    EducationAndSkills
  },
  setup () {
    const assignedFiltersTalentStore = useFiltersTalentStore()
    const offset = ref<number>(0)
    const rowsDefault = 6
    const loadingCards = ref(false)
    const showDetail = ref(false)
    const { talents, total, getTalents, loadingTalents } = useFindTalents()
    const talentSelected = ref<Talent>(talents.value[0])
    getTalents(1, rowsDefault, assignedFiltersTalentStore.getQueryParam)
    const changePage = async (page: number) => {
      await getTalents(page + 1, rowsDefault, assignedFiltersTalentStore.getQueryParam)
      offset.value = page * rowsDefault
    }

    const getTaletsFilter = async () => {
      showDetail.value = false
      loadingCards.value = true
      console.log('aqiooooo')
      await getTalents(1, rowsDefault, assignedFiltersTalentStore.getQueryParam)
      loadingCards.value = false
    }
    const getProfile = async (talent: Talent) => {
      talentSelected.value = talent
      showDetail.value = true
    }
    const goToList = () => {
      showDetail.value = false
    }
    return {
      talents,
      getTalents,
      loadingTalents,
      loadingCards,
      getTaletsFilter,
      total,
      offset,
      changePage,
      rowsDefault,
      getProfile,
      showDetail,
      talentSelected,
      goToList
    }
  }
})
