import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, renderList as _renderList, Fragment as _Fragment, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-0938ba51"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 1,
  class: "p-d-sm-flex p-p-0 home-container"
}
const _hoisted_2 = { class: "p-col-12 p-lg-3 p-md-3 p-sm-3" }
const _hoisted_3 = {
  key: 0,
  class: "p-col-9 p-px-4"
}
const _hoisted_4 = {
  key: 1,
  class: "p-col-12 p-lg-9 p-md-9 p-sm-9 p-px-4 cards-grid"
}
const _hoisted_5 = { class: "p-pb-5 title" }
const _hoisted_6 = { class: "search-info-container p-px-4" }
const _hoisted_7 = { class: "p-col-12 search-info-header" }
const _hoisted_8 = {
  key: 1,
  class: "container container-empty margin"
}
const _hoisted_9 = { class: "container-empty__title" }
const _hoisted_10 = { class: "container-empty__subtitle" }
const _hoisted_11 = {
  key: 0,
  class: "card-container paginator-container"
}
const _hoisted_12 = {
  key: 2,
  class: "p-col-12 p-lg-9 cards-grid"
}
const _hoisted_13 = { class: "info-container p-jc-center p-ai-center p-ai-md-stretch p-col-12 p-md-12" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_LoadingScreen = _resolveComponent("LoadingScreen")!
  const _component_FilterTalent = _resolveComponent("FilterTalent")!
  const _component_CardTalent = _resolveComponent("CardTalent")!
  const _component_Paginator = _resolveComponent("Paginator")!
  const _component_PersonalInfo = _resolveComponent("PersonalInfo")!
  const _component_EducationAndSkills = _resolveComponent("EducationAndSkills")!

  return (_ctx.loadingTalents && !_ctx.loadingCards)
    ? (_openBlock(), _createBlock(_component_LoadingScreen, { key: 0 }))
    : (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createVNode(_component_FilterTalent, {
            onGetTalents: _cache[0] || (_cache[0] = ($event: any) => (_ctx.getTaletsFilter()))
          })
        ]),
        (_ctx.loadingCards)
          ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
              _createVNode(_component_LoadingScreen)
            ]))
          : (!_ctx.showDetail)
            ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                _createElementVNode("h3", _hoisted_5, _toDisplayString(_ctx.$t('talents.headline')), 1),
                _createElementVNode("div", _hoisted_6, [
                  _createElementVNode("div", _hoisted_7, _toDisplayString(_ctx.$t('talents.subtitle')), 1)
                ]),
                _createElementVNode("div", {
                  class: _normalizeClass({ 'p-grid cards-container': _ctx.talents?.length > 0 })
                }, [
                  (_ctx.talents?.length)
                    ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList(_ctx.talents, (talent) => {
                        return (_openBlock(), _createElementBlock("div", {
                          class: "card-container p-col-12 p-lg-4 p-md-6 p-sm-12",
                          key: talent._id
                        }, [
                          _createVNode(_component_CardTalent, {
                            talent: talent,
                            onGetProfile: _ctx.getProfile
                          }, null, 8, ["talent", "onGetProfile"])
                        ]))
                      }), 128))
                    : (_openBlock(), _createElementBlock("div", _hoisted_8, [
                        _createElementVNode("p", _hoisted_9, _toDisplayString(_ctx.$t('talents.alerts.notFound')), 1),
                        _createElementVNode("p", _hoisted_10, _toDisplayString(_ctx.$t('talents.alerts.tryAgain')), 1)
                      ]))
                ], 2),
                (_ctx.talents?.length)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_11, [
                      _createVNode(_component_Paginator, {
                        first: _ctx.offset,
                        rows: _ctx.rowsDefault,
                        totalRecords: _ctx.total * _ctx.rowsDefault,
                        onPage: _cache[1] || (_cache[1] = ($event: any) => (_ctx.changePage($event.page)))
                      }, null, 8, ["first", "rows", "totalRecords"])
                    ]))
                  : _createCommentVNode("", true)
              ]))
            : _createCommentVNode("", true),
        (_ctx.talents?.length && _ctx.showDetail)
          ? (_openBlock(), _createElementBlock("div", _hoisted_12, [
              _createElementVNode("div", _hoisted_13, [
                _createVNode(_component_PersonalInfo, {
                  class: "p-col-12",
                  talent: _ctx.talentSelected,
                  onGoBack: _ctx.goToList
                }, null, 8, ["talent", "onGoBack"]),
                _createVNode(_component_EducationAndSkills, {
                  class: "p-col-12",
                  talent: _ctx.talentSelected
                }, null, 8, ["talent"])
              ])
            ]))
          : _createCommentVNode("", true)
      ]))
}