
import { defineComponent, PropType, ref, computed, watch } from 'vue'
import { RouteLocationRaw } from 'vue-router'
import { useUserSessionStore } from '@/store/modules/commons/user-session-store'
import Menu from 'primevue/menu'
import { useI18n } from 'vue-i18n'

export default defineComponent({
  components: {
    Menu
  },
  props: {
    showGoBack: {
      type: Boolean,
      default: true
    },
    backTo: {
      type: Object as PropType<RouteLocationRaw>
    }
  },
  setup () {
    const userSession = useUserSessionStore()
    const isAuth = computed(() => userSession.isAuthenticated)
    const logout = userSession.logout
    const avatarURL = ref('')
    const menu = ref()
    const { t } = useI18n({ useScope: 'global' })

    watch(isAuth, (value) => {
      if (isAuth.value) {
        const { avatar } = userSession.getUser
        avatarURL.value = avatar
      }
    }, { immediate: true })

    const items = ref([
      {
        label: () => t('header.buttons.logout'),
        icon: 'pi pi-sign-out',
        command: () => {
          logout()
        }
      }
    ])

    const toggle = (event: any) => {
      menu.value.toggle(event)
    }

    return { isAuth, avatarURL, menu, toggle, items }
  }
})
