import { defineStore } from 'pinia'
import { Locale } from '@/models/locale/locale'
import { getLocale, getCountryCode } from '@/api/locale.api'
import { useI18nStore } from './i18n.store'
import axios from 'axios'

const LANGUAGES: { [key: string]: string; } = {
  ar: 'es-AR',
  uy: 'es-AR',
  mx: 'es-MX',
  br: 'pt-BR',
  lan: 'es-ES',
  es: 'es-ES',
  default: 'es-ES'
}

export const useLocaleStore = defineStore({
  id: 'locale',
  state: () => ({
    locale: null as Locale | null
  }),
  getters: {
    getLocale (): Locale | null {
      return this.locale
    }
  },
  actions: {
    async fetchLocale (lang = 'es-MX') {
      this.locale = await getLocale(lang)

      useI18nStore().setLocale(this.locale.locale)
      useI18nStore().setLocaleMessages(this.locale.locale, this.locale?.translations)
    },
    async fetchLocaleByIp () {
      const { data: ip } = await axios.get<string>('https://api.ipify.org')
      const countryCode = await getCountryCode(ip)
      this.locale = await getLocale(LANGUAGES[countryCode])

      useI18nStore().setLocale(this.locale.locale)
      useI18nStore().setLocaleMessages(this.locale.locale, this.locale?.translations)
    }
  }
})
