<template>
  <div class="p-d-flex p-flex-wrap filter-content">
    <div class="grade-filter p-col-12 p-lg-12">
      <h3 class="filter-title">{{ title }}</h3>
      <Accordion ref="accordionComponent" class="accordion-header" :activeIndex="activeIndex">
        <AccordionTab :header="header">
          <div class="p-field-checkbox" v-for="item in items" :key="item.value">
            <Checkbox
              class="ch-checkbox"
              v-model="selectedItems"
              @change="closeTab()"
              :id="item.value"
              :name="item.value"
              :value="item.value"
            />
            <label class="text" :for="item.value">{{ $t(item.name) }}</label>
          </div>
        </AccordionTab>
      </Accordion>
      <div class="items-selected p-mb-3">
        <label class="item-detail" for="{{item}}" v-for="item in selectedItems" :key="item">{{ item }}
          <Button
            icon="pi pi-times"
            class="ch-button"
            @click="removeItem(item)"
          />
        </label>
      </div>
    </div>
  </div>
</template>

<script src="./MultiSelectCheckbox.ts"></script>
<style scoped lang="scss" src="./MultiSelectCheckbox.scss"></style>
