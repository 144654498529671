import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-bc2728a2"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "form-container" }
const _hoisted_2 = { class: "input-container" }
const _hoisted_3 = { for: "email" }
const _hoisted_4 = {
  key: 0,
  class: "form-error"
}
const _hoisted_5 = {
  key: 1,
  class: "form-error"
}
const _hoisted_6 = {
  key: 0,
  class: "register-success"
}
const _hoisted_7 = { class: "register_success__icon-info" }
const _hoisted_8 = ["src"]
const _hoisted_9 = { class: "register_success__text" }
const _hoisted_10 = ["src"]
const _hoisted_11 = { class: "p-dialog-footer p-col-12" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_InputText = _resolveComponent("InputText")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("p", null, _toDisplayString(_ctx.$t('recoveryForm.instructions')), 1),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("label", _hoisted_3, _toDisplayString(_ctx.$t('registerForm.labels.email')), 1),
      _createVNode(_component_InputText, {
        type: "text",
        placeholder: _ctx.$t('registerForm.placeholders.email'),
        modelValue: _ctx.v$.email.$model,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.v$.email.$model) = $event)),
        onInput: _ctx.resetValidate,
        class: _normalizeClass(["p-inputtext-lg dark-input p-my-1", { 'input-error': _ctx.v$.email.$error }])
      }, null, 8, ["placeholder", "modelValue", "onInput", "class"]),
      (_ctx.v$.email.$errors[0]?.$validator == 'email')
        ? (_openBlock(), _createElementBlock("div", _hoisted_4, _toDisplayString(_ctx.$t('registerForm.alerts.invalidEmail')), 1))
        : _createCommentVNode("", true),
      (_ctx.v$.email.$errors[0]?.$validator == 'required')
        ? (_openBlock(), _createElementBlock("div", _hoisted_5, _toDisplayString(_ctx.$t('registerForm.alerts.emptyField')), 1))
        : _createCommentVNode("", true)
    ]),
    (_ctx.recoverySuccess)
      ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
          _createElementVNode("div", _hoisted_7, [
            _createElementVNode("img", {
              src: require('@/assets/icons/info.svg'),
              alt: "info-icon"
            }, null, 8, _hoisted_8)
          ]),
          _createElementVNode("div", _hoisted_9, _toDisplayString(_ctx.$t('recoveryForm.success')), 1),
          _createElementVNode("div", {
            onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.closeSuccessMsg && _ctx.closeSuccessMsg(...args))),
            class: "register_success__icon-close"
          }, [
            _createElementVNode("img", {
              src: require('@/assets/icons/close.svg'),
              alt: "close-icon"
            }, null, 8, _hoisted_10)
          ])
        ]))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_11, [
      _createElementVNode("button", {
        class: "send-button",
        onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.submit && _ctx.submit(...args)))
      }, _toDisplayString(_ctx.$t('recoveryForm.submit')), 1)
    ])
  ]))
}