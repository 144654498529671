
<template>
<div class="container">
  <div class="p-d-flex p-py-3 container__card p-flex-wrap">
    <div class="p-col-12">
      <AvatarTalent :talentId="talent._id" :name="talent.name" :avatar="talent.avatar" :email="talent.email" :background="talent.background" :socialNetworks="socialNetworks"></AvatarTalent>
    </div>
    <div class="description p-col-12 p-lg-12">
      <DescriptionTalent :presentation="talent.presentation" :skills="talent.skills"
        :languages="talent.languages" :country="talent.location?.country" :city="talent.location?.city"
        :curriculum="talent.curriculum" :name="talent.name" :talentId="talent._id" :finalAssignment="lastFinalAssignment"></DescriptionTalent>
    </div>
    <div class="container">
      <button @click="($emit('getProfile', talent))" class="ch-button-green btn-coder ch-buttom-custom">
        {{ $t('talents.profile.viewProfile') }}
      </button>
    </div>
  </div>
</div>
</template>

<script src="./CardTalent.ts"></script>
<style scoped lang="scss" src="./CardTalent.scss"></style>
