
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'Expired',
  setup () {
    return {
    }
  }
})
