import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, Teleport as _Teleport, openBlock as _openBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-28e3a09c"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "loading-screen" }
const _hoisted_2 = { class: "spinner-container" }
const _hoisted_3 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ProgressSpinner = _resolveComponent("ProgressSpinner")!

  return (_openBlock(), _createBlock(_Teleport, { to: "body" }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createVNode(_component_ProgressSpinner),
        _createElementVNode("img", {
          src: _ctx.logoImage,
          alt: "coderhouse-logo"
        }, null, 8, _hoisted_3)
      ])
    ])
  ]))
}