import { defineComponent, Ref, ref, defineExpose } from 'vue'
import useGetSkills from '@/hooks/useGetSkills'
import { Skill } from '@/models/talents'
import { useFiltersTalentStore } from '@/store/modules/commons/filters-talent-store'
import InputItemSearch from '@/components/commons/InputItemSearch/InputItemSearch.vue'

export default defineComponent({
  name: 'FilterSkill',
  components: {
    InputItemSearch
  },
  setup () {
    const assignedFiltersTalentStore = useFiltersTalentStore()
    const selectedSkills: Ref<Skill[]> = ref([])
    const search = ref()
    const { skills } = useGetSkills()
    const techSkills: Ref<Skill[]> = ref([])
    const softSkills: Ref<Skill[]> = ref([])

    selectedSkills.value = assignedFiltersTalentStore.skills.slice()

    const selectSkill = (skill: Skill) => {
      if (selectedSkills.value.indexOf(skill) === -1) selectedSkills.value.push(skill)
      search.value = ''
      applySkills()
    }

    const searchSkills = (searchString: string) => {
      setTimeout(() => {
        if (searchString.trim().length) {
          techSkills.value = skills.value.filter((skill) => {
            return skill.name.toLowerCase().startsWith(searchString.toLowerCase()) && skill.type === 'Tech'
          })
          softSkills.value = skills.value.filter((skill) => {
            return skill.name.toLowerCase().startsWith(searchString.toLowerCase()) && skill.type === 'Soft'
          })
        }
      }, 250)
    }

    const removeSkill = (skill: Skill) => {
      selectedSkills.value = selectedSkills.value.filter((s: Skill) => s.name !== skill.name)
      applySkills()
    }

    const applySkills = () => {
      assignedFiltersTalentStore.updateSkills(selectedSkills.value)
    }

    function clearFilter () {
      selectedSkills.value = []
    }

    defineExpose({
      clearFilter
    })

    return {
      search,
      removeSkill,
      selectedSkills,
      techSkills,
      selectSkill,
      searchSkills,
      clearFilter
    }
  }
})
