
import { defineComponent, onMounted } from 'vue'
import ConfirmDialog from 'primevue/confirmdialog'
import Toast from 'primevue/toast'
import { useUserSessionStore } from '@/store/modules/commons/user-session-store'
import { useAuth } from '@/auth/useAuthService'

export default defineComponent({
  components: {
    ConfirmDialog,
    Toast
  },
  setup () {
    const session = useUserSessionStore()
    const { initializeAuth } = useAuth()

    onMounted(() =>
      initializeAuth({
        domain: process.env.VUE_APP_AUTH0_DOMAIN as string,
        client_id: process.env.VUE_APP_AUTH0_CLIENTID as string,
        audience: process.env.VUE_APP_AUTH0_AUDIENCE as string,
        scope: 'openid profile email'
      })
    )

    return {
      session
    }
  }
})
