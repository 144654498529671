import AvatarTalent from '@/components/talent/card/AvatarTalent/AvatarTalent.vue'
import DescriptionTalent from '@/components/talent/card/DescriptionTalent/DescriptionTalent.vue'
import { Talent, FinalAssignment, SocialNetworks } from '@/models/talents'
import { defineComponent, ref, PropType, onMounted, Ref } from 'vue'
import finalAssignmentsFilter from '@/components/talent/utils/FinalAssignmentsFilter'
import SocialNetworksOrder from '@/components/talent/utils/SocialNetworksOrder'
import { RouteNames } from '@/router/route-names'

export default defineComponent({
  name: 'CardTalent',
  components: {
    AvatarTalent,
    DescriptionTalent
  },
  props: {
    talent: {
      required: true,
      type: Object as PropType<Talent>
    },
    getProfile: {
      type: Function,
      required: false
    }
  },
  setup (props) {
    const lastFinalAssignment: Ref<FinalAssignment> = ref({ name: '', link: '' })
    const { getLastAssignment } = finalAssignmentsFilter(props.talent)

    const socialNetworks: Ref<SocialNetworks[]> = ref([])
    const { getOrderedSocialNetworks } = SocialNetworksOrder(props.talent)

    onMounted(() => {
      lastFinalAssignment.value = getLastAssignment()
      socialNetworks.value = getOrderedSocialNetworks(2)
    })

    return {
      lastFinalAssignment,
      socialNetworks,
      RouteNames
    }
  }
})
