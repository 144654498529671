
import { defineComponent } from 'vue'
import { useRoute } from 'vue-router'
import PersonalInfo from '@/components/talent/talent-profile/personal-info/PersonalInfo.vue'
import EducationAndSkills from '@/components/talent/talent-profile/education/EducationAndSkills.vue'
import useFindOneTalent from '@/hooks/useFindOneTalent'
import LoadingScreen from '@/components/commons/LoadingScreen.vue'

export default defineComponent({
  name: 'TalentProfile',
  components: {
    PersonalInfo,
    EducationAndSkills,
    LoadingScreen
  },
  setup () {
    const { params: { talentId } } = useRoute()

    const { talent, getTalent, loadingTalents } = useFindOneTalent(talentId as string)

    return {
      talent,
      getTalent,
      loadingTalents
    }
  }
})
