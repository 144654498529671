import usersApi from '@/api/users.api'
import { ref, onMounted } from 'vue'
import { useI18n } from 'vue-i18n'

export default function useFindEmailVerifications (token:string) {
  const loadingVerifications = ref<boolean>(true)
  const response = ref(false)

  const getVerifications = async () => {
    try {
      loadingVerifications.value = true
      const data = await usersApi.findEmailVerifications(token)

      if (data.email_verified === true) {
        response.value = true
      }
    } catch (error) {
      loadingVerifications.value = false
      console.error(error)
    } finally {
      loadingVerifications.value = false
    }
  }
  onMounted(getVerifications)

  return {
    getVerifications,
    loadingVerifications,
    response
  }
}
