import { defineComponent, onMounted, ref } from 'vue'
import FilterCourse from '@/components/talent/card/FilterTalent/FilterCourse/FilterCourse.vue'
import FilterLanguage from '@/components/talent/card/FilterTalent/FilterLanguage/FilterLanguage.vue'
import FilterLocation from '@/components/talent/card/FilterTalent/FilterLocation/FilterLocation.vue'
import FilterSkill from '@/components/talent/card/FilterTalent/FilterSkill/FilterSkill.vue'
import FilterContractType from '@/components/talent/card/FilterTalent/FilterContractType/FilterContractType.vue'
import OverlayPanel from 'primevue/overlaypanel'
import Dialog from 'primevue/dialog'
import { useFiltersTalentStore } from '@/store/modules/commons/filters-talent-store'
import Accordion from 'primevue/accordion'
import AccordionTab from 'primevue/accordiontab'
import { useToast } from 'primevue/usetoast'
import { ToastLife, ToastSeverities, ToastSummaries } from '@/models/primevue/toast'
import { useI18n } from 'vue-i18n'

export default defineComponent({
  name: 'FilterTalent',
  components: {
    FilterCourse,
    FilterLanguage,
    FilterLocation,
    FilterSkill,
    FilterContractType,
    OverlayPanel,
    Accordion,
    AccordionTab,
    Dialog
  },
  props: {
    getTalets: {
      type: Function,
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      default: () => {},
      required: false
    }
  },
  emits: ['getTalents', 'paretntEvent'],
  setup (props, ctx) {
    const opCourses = ref()
    const opSkills = ref()
    const opLocations = ref()
    const opLanguages = ref()
    const opContracts = ref()
    const keyValue = ref('')
    const displayModal = ref(false)
    const assignedFiltersTalentStore = useFiltersTalentStore()
    const locationComponent = ref()
    const skillComponent = ref()
    const languageComponent = ref()
    const courseComponent = ref()
    const focusSkills = ref(false)
    const focusLanguages = ref(false)
    const focusLocations = ref(false)
    const focusCourses = ref(false)
    const showFilters = ref(false)
    const toast = useToast()
    const { t } = useI18n({ useScope: 'global' })

    onMounted(() => {
      checkSize()
    })

    window.addEventListener('resize', () => {
      checkSize()
    })

    const checkSize = () => {
      if (window.innerWidth < 568) {
        if (showFilters.value) { showFilters.value = false }
      } else {
        if (!showFilters.value) { showFilters.value = true }
      }
    }

    const deleteFilter = () => {
      assignedFiltersTalentStore.updateLocations([])
      assignedFiltersTalentStore.updateSkills([])
      assignedFiltersTalentStore.updateCourses({ courses: [], states: [], grade: [] })
      assignedFiltersTalentStore.updateLanguages({ language: [], level: [] })
      assignedFiltersTalentStore.updateContractTypes([])
      assignedFiltersTalentStore.updateKeyFilter('')
      assignedFiltersTalentStore.updateCourseCategories([])
      opCourses.value.clearFilter()
      opSkills.value.clearFilter()
      opLocations.value.clearFilter()
      opLanguages.value.clearFilter()
      opContracts.value.clearFilter()
      keyValue.value = ''
      searchTalents()
      toast.add({ severity: ToastSeverities.Success, summary: ToastSummaries.Success, detail: t('talents.contactButton.toast.filtersDeleted'), life: ToastLife.Default })
    }

    const applyFilter = () => {
      locationComponent.value.applyFilter()
      skillComponent.value.applySkills()
      languageComponent.value.applyFilter()
      courseComponent.value.applyFilter()
    }

    const sendDataLayer = (type: string) => {
      window.dataLayer.push({
        event: 'trackEvent',
        eventCategory: 'codertalent',
        eventAction: 'clic-filtro',
        eventLabel: `${type}`
      })
    }
    const searchTalents = async () => {
      if (window.innerWidth < 568) {
        showFilters.value = false
      }
      ctx.emit('getTalents')
    }
    const changeViewFilters = () => {
      showFilters.value = !showFilters.value
    }
    const changeKeyValue = () => {
      assignedFiltersTalentStore.updateKeyFilter(keyValue.value)
    }
    return {
      opCourses,
      opSkills,
      opLocations,
      opLanguages,
      opContracts,
      assignedFiltersTalentStore,
      displayModal,
      deleteFilter,
      applyFilter,
      locationComponent,
      skillComponent,
      courseComponent,
      languageComponent,
      sendDataLayer,
      focusCourses,
      focusSkills,
      focusLanguages,
      focusLocations,
      showFilters,
      changeViewFilters,
      searchTalents,
      keyValue,
      changeKeyValue
    }
  }
})
