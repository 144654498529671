<template>
    <div class="tutorial">
        <h1 class="tutorial-title">{{ $t('home.tutorial.title') }}</h1>

        <div class="accordion-container">

            <div class="progress-bar-container">

                <div class="bullet bullet--1 bullet--on" ref="bullet1"></div>
                <div class="progress-bar1" ref="bar1">
                    <div ref="fillBar1"></div>
                </div>

                <div class="bullet bullet--2 bullet--off" ref="bullet2"></div>
                <div class="progress-bar2" ref="bar2">
                    <div ref="fillBar2"></div>
                </div>

                <div class="bullet bullet--3 bullet--off" ref="bullet3"></div>
                <div class="progress-bar3">
                    <div class="fill-animation"></div>
                </div>

            </div>

            <Accordion :activeIndex="active" class="accordion" @tab-open="animateStep">

                <AccordionTab :header="$t('home.tutorial.stepOne.title')">
                    <p>
                        <ul>
                            <li>{{ $t('home.tutorial.stepOne.text') }}</li>
                        </ul>
                    </p>
                </AccordionTab>
                <AccordionTab :header="$t('home.tutorial.stepTwo.title')">
                    <p>
                        <ul>
                            <li>{{ $t('home.tutorial.stepTwo.text') }}</li>
                        </ul>
                    </p>
                </AccordionTab>
                <AccordionTab :header="$t('home.tutorial.stepThree.title')">
                    <p>
                        <ul>
                            <li>{{ $t('home.tutorial.stepThree.text') }}</li>
                        </ul>
                    </p>
                </AccordionTab>
            </Accordion>

        </div>
      </div>

    <div class="carousel-main">

        <div class="carousel-background">
            <transition-group name="list" tag="p" class="img-container">
                <img :src="require('@/assets/images/Busca.svg')" alt="" v-if="active === 0" class="carousel-img">
                <img :src="require('@/assets/images/Elegi.svg')" alt="" v-if="active === 1" class="carousel-img">
                <img :src="require('@/assets/images/Contacta.svg')" alt="" v-if="active === 2" class="carousel-img">
            </transition-group>
        </div>

    </div>
</template>
<script>
import { defineComponent, ref, onMounted, watch } from 'vue'
import Accordion from 'primevue/accordion'
import AccordionTab from 'primevue/accordiontab'

export default defineComponent({
  name: 'tutorial',
  components: {
    Accordion,
    AccordionTab
  },
  setup () {
    const active = ref(0)
    const bullet1 = ref(null)
    const bullet2 = ref(null)
    const bullet3 = ref(null)
    const bar1 = ref(null)
    const fillBar1 = ref(null)
    const bar2 = ref(null)
    const fillBar2 = ref(null)

    let timeOutID = null

    const animateStep = (event) => {
      active.value = event.index
      if (timeOutID) clearTimeout(timeOutID)

      animateTutorial(event.index)
      timeOutID = setTimeout(() => { active.value = active.value >= 2 ? 0 : event.index + 1 }, 5000)
    }

    const animateTutorial = (currentStep) => {
      switch (currentStep) {
        case 0:
          bar1.value.classList.remove('filled-bar')
          bar2.value.classList.remove('filled-bar')

          bullet2.value.classList.remove('bullet--active')
          bullet3.value.classList.remove('bullet--active')
          bullet2.value.classList.remove('bullet--on')
          bullet3.value.classList.remove('bullet--on')
          bullet2.value.classList.add('bullet--off')
          bullet3.value.classList.add('bullet--off')

          bullet2.value.setAttribute('style', 'bottom: 90px')
          bullet3.value.setAttribute('style', 'bottom: 25px')

          bar1.value.setAttribute('style', 'top: 30px; bottom: 85px;')
          bar2.value.setAttribute('style', 'top: calc(100% - 85px);bottom: 25px;')

          bullet1.value.classList.add('bullet--active')

          fillBar1.value.classList.add('fill-animation')
          fillBar2.value.classList.remove('fill-animation')
          break
        case 1:
          bar1.value.classList.add('filled-bar')
          fillBar1.value.classList.remove('fill-animation')
          bar2.value.classList.remove('filled-bar')
          fillBar2.value.classList.remove('fill-animation')

          bullet1.value.classList.remove('bullet--active')
          bullet3.value.classList.remove('bullet--active')
          bullet3.value.classList.remove('bullet--on')
          bullet3.value.classList.add('bullet--off')

          bullet2.value.setAttribute('style', 'top: 90px;')
          bullet3.value.setAttribute('style', 'bottom: 25px')

          bar1.value.setAttribute('style', 'top: 30px; bottom: calc(100% - 90px);')
          bar2.value.setAttribute('style', 'top: 90px; bottom: 25px;')

          bullet2.value.classList.add('bullet--active')
          bullet2.value.classList.remove('bullet--off')
          fillBar2.value.classList.add('fill-animation')
          break
        case 2:
          bullet1.value.classList.remove('bullet--active')
          bullet2.value.classList.remove('bullet--active')
          bullet2.value.classList.remove('bullet--off')
          bullet2.value.classList.add('bullet--on')
          bullet3.value.classList.remove('bullet--off')
          bullet3.value.classList.add('bullet--active')

          bar1.value.classList.add('filled-bar')
          bar2.value.classList.add('filled-bar')
          fillBar1.value.classList.remove('fill-animation')
          fillBar2.value.classList.remove('fill-animation')

          bar1.value.setAttribute('style', 'top: 30px; bottom: calc(100% - 90px);')

          bullet2.value.setAttribute('style', 'top: 90px;')
          bullet3.value.setAttribute('style', 'top: 152px')

          bar2.value.setAttribute('style', 'top: 90px; bottom: calc(100% - 152px);')

          break
        default:
          currentStep = 0
          break
      }
    }

    onMounted(() => animateStep({ index: 0 }))

    watch(active, (active) => {
      animateStep({ index: active })
    })

    return {
      active,
      bullet1,
      bullet2,
      bullet3,
      bar1,
      bar2,
      fillBar1,
      fillBar2,
      animateStep
    }
  }
})
</script>

<style lang="scss" scoped src="./Tutorial.scss"></style>
