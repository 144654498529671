import { Talent, ITalent, TalentDto, LocationsTalentDto, Skill, CoursesTalentDto, CategoriesTalent } from '@/models/talents'
import axios from 'axios'
import { Api } from './api'

async function findTalents (page = 1, limit = 6, queryParams = ''): Promise<TalentDto> {
  return Api.get<TalentDto>(`/talents?page=${page}&limit=${limit}&${queryParams}`)
}

async function findTalentById (talentId: string) : Promise<Talent> {
  const talent = await Api.get<ITalent>(`/talents/${talentId}`)
  return convertDtoToTalent(talent)
}

async function findLocationsTalents (): Promise<LocationsTalentDto[]> {
  return Api.get<LocationsTalentDto[]>('/talents/locations')
}

async function findLanguagesTalents (): Promise<string[]> {
  return Api.get<string[]>('/talents/languages')
}

async function getSkills (): Promise<Skill[]> {
  return Api.get<Skill[]>('/skills')
}

async function getCourses (): Promise<CoursesTalentDto[]> {
  return Api.get<CoursesTalentDto[]>('talents/courses')
}

async function getCategories (): Promise<CategoriesTalent[]> {
  return Api.get<CategoriesTalent[]>('talents/categories')
}

function convertDtoToTalent (dto: ITalent): Talent {
  console.log('DATAL: ', dto)
  return new Talent(
    dto.name,
    dto.email,
    dto.presentation,
    dto.coderCourses,
    dto.location,
    dto.avatar,
    dto.background,
    dto.education,
    dto.languages,
    dto.skills,
    dto.socialNetworks,
    dto.workExperience,
    dto.curriculum,
    dto._id
  )
}

export {
  findTalents,
  findTalentById,
  getSkills,
  getCourses,
  getCategories,
  findLocationsTalents,
  findLanguagesTalents
}
