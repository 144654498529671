
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'InputItemSearch',
  props: {
    placeholder: {
      type: String,
      default: '',
      required: false
    },
    title: {
      type: String,
      default: '',
      required: false
    },
    search: {
      type: String,
      default: '',
      required: false
    },
    searchItems: {
      type: Function,
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      default: () => {},
      required: false
    },
    removeItem: {
      type: Function,
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      default: () => {},
      required: false
    },
    selectedItems: {
      type: Array,
      required: false
    },
    selectItems: {
      type: Object,
      required: false
    },
    itemsList: {
      type: Object,
      required: false
    }
  }
})
