import { defineComponent, onMounted, Ref, ref, defineExpose } from 'vue'
import { useFiltersTalentStore } from '@/store/modules/commons/filters-talent-store'
import { ToastLife, ToastSeverities, ToastSummaries } from '@/models/primevue/toast'
import { useToast } from 'primevue/usetoast'
import { findLocationsTalents } from '@/api/talents.api'
import { LocationsTalentDto, Country } from '@/models/talents'
import InputItemSearch from '@/components/commons/InputItemSearch/InputItemSearch.vue'
import { useI18n } from 'vue-i18n'

export default defineComponent({
  name: 'FilterLocation',
  components: {
    InputItemSearch
  },
  props: {
  },
  setup () {
    const assignedFiltersTalentStore = useFiltersTalentStore()
    const locations = ref<LocationsTalentDto[]>([])
    const filteredCoutry: Ref<{ name: string }[]> = ref([])
    const selectedCountries: Ref<Country[]> = ref([])
    const search = ref<string>('')
    const toast = useToast()
    const { t } = useI18n({ useScope: 'global' })

    selectedCountries.value = assignedFiltersTalentStore.locations.slice()

    const getLocations = async () => {
      try {
        locations.value = await findLocationsTalents()
        locations.value = locations.value
          .filter((country) => country.name !== null)
          .map((country) => {
            return { name: country.name, cities: [] }
          })
        filteredCoutry.value = locations.value
      } catch (error) {
        toast.add({
          summary: ToastSummaries.Error,
          detail: t('talents.alerts.loadError'),
          severity: ToastSeverities.Error,
          life: ToastLife.Default
        })
        console.error(error)
      }
    }

    onMounted(() => {
      getLocations()
    })

    const searchCountry = () => {
      filteredCoutry.value = locations.value.filter((country) => country.name.toLowerCase().startsWith(search.value.toLowerCase()))
    }

    const selectCountry = (country: Country) => {
      if (selectedCountries.value.indexOf(country) === -1) selectedCountries.value.push(country)
      search.value = ''
      applyCountry()
    }

    const removeCountry = (country: Country) => {
      selectedCountries.value = selectedCountries.value.filter((s: Country) => s.name !== country.name)
      applyCountry()
    }

    const applyCountry = () => {
      assignedFiltersTalentStore.updateLocations(selectedCountries.value)
    }

    function clearFilter () {
      selectedCountries.value = []
    }

    defineExpose({
      clearFilter
    })

    return {
      locations,
      search,
      assignedFiltersTalentStore,
      filteredCoutry,
      selectedCountries,
      searchCountry,
      selectCountry,
      removeCountry,
      clearFilter
    }
  }
})
