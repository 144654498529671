
import { defineComponent } from 'vue'
import ProgressSpinner from 'primevue/progressspinner'
export default defineComponent({
  components: {
    ProgressSpinner
  },
  setup () {
    // eslint-disable-next-line @typescript-eslint/no-var-requires
    const logoImage = require('@/assets/icons/coderhouse/icon-96.png')
    return {
      logoImage
    }
  }
})
